.manage-specification-modal .ant-modal-body {
  padding: 12px 59.25px
}

.manage-specification-modal .ant-modal-header {
  padding: 16px 59.25px;
}

.manage-specification-tabs .ant-tabs-tab {
  padding: 12px 24px;
}

.manage-specification-tabs .ant-tabs-tab.ant-tabs-tab-active {
  font-weight: bold;
}

.manage-specification-tabs .ant-tabs-content-holder {
  padding: 24px 0;
}

.manage-specification-form .ant-form-item-label {
  white-space: unset;
}


.manage-specification-tabs .ant-upload-list-picture-card-container, .manage-specification-tabs .ant-upload.ant-upload-select-picture-card {
  height: 150px;
  width: 150px;
  border: 1px dashed #ACACAC;
  border-radius: 5px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
}