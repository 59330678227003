.App {
  font-family: sans-serif;
  text-align: center;
}
.page {
  max-width: 95%;
  color: rgb(236, 26, 26);
  background-color: white;
}

.page img {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.flip__book__flex {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 50px 0; */
  padding: 10px 0px !important;
}

.button {
  position: absolute;
  color: rgb(236, 236, 236);
  z-index: 1000;
  cursor: pointer;
  top: 40%;
}
.button i {
  font-size: 50px;
}
.previous {
  left: 1%;
}
.next {
  right: 1%;
}
.info-page {
  position: absolute;
  bottom: 20px;
}
.flip__book__flex--17start{
  transform: translateX(-17.5%) !important;
}
.flip__book__flex--17end{
  transform: translateX(17.5%) !important;
}
.flip__book--overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 111;

}

canvas{
  max-width: calc(100vw*0.7 / 2);
  max-height: calc(100vw*0.93 / 2);
}

@media only screen and (max-width: 1200px) {

.button i {
  font-size: 30px;
}
canvas{
  max-width: 600px;
  max-height: 848px;
}

}
@media only screen and (max-width: 1100px) {
.stf__wrapper{
  padding-bottom: 100% !important;
}
.main__page--mode {
  bottom: 50px;
}
.flip__book__flex{
  /* padding-top: 100px !important; */
}
.flip__book__flex--17start{
  transform: translateX(0%) !important;
}
.flip__book__flex--17end{
  transform: translateX(0%) !important;
}
}

@media only screen and (max-width: 600px) {
  
  canvas{
    max-width: 350px;
    max-height: 495px;
  }
}