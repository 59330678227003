.login {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
    position: relative;
}

.login__overlay {
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.login__form-container{
    padding:0 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #1b1b1b;
    max-width: 520px;
    width: 100%;
}

.login__form {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 20px;
    padding: 25px 50px;
    text-align: center;
}

.login__form > img {
    width: 100px;
    height: 100px;
}

.login__form > h1 {
    font: normal normal bold 20px/27px Open Sans;
    margin: 20px 0 30px;
}

.login__form__main {
}

.login-form-input{
    min-height: 40px;
}

.login-form-button{
    width: 100%;
    border-radius: 4px;
}
