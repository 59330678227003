/* News generation */
.news-generation {
    position: relative;
    padding: 60px 0;
    color: #ffffff;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

.news-generation__title {
    padding: 0 0 60px;
    font: normal normal bold 24px/38px Open Sans;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
}

.news-generation__overlay {
    background: transparent linear-gradient(180deg, #00558d 0%, #82cdff 100%) 0% 0% no-repeat padding-box;
    opacity: 0.9;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
}
.news-container{
    width: 100%;
    position: relative
}
.container{
    position: relative;
    height: auto
}
/* News Page */
.news {
    position: relative;
}

.news__main {
    color: #1b1b1b;
    margin-bottom: 60px;
}

.news__main__title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.news__main__title {
    width: 400px;
    background: transparent linear-gradient(278deg, #cc0001 0%, #00558d 100%) 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 15px;
    font-size: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.news__main__title>h3 {
    color: #ffffff;
    font: normal normal normal 20px/20px Open Sans;
    text-transform: uppercase;
}

.news__main__title__circle {
    width: 25px;
    height: 25px;
    border: 8px solid #ffffff;
    margin-right: 10px;
    border-radius: 100%;
}

.news__main__detail {
    display: flex;
    font: italic normal normal 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #ffffff;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    min-width: max-content;
    margin: 0 0 10px 10px;
}

.news__main__detail:hover {
    color: #ffffff;
}

.news__main__detail--red:hover {
    color: #cc0001;
}

.news__main__detail__icon {
    margin-left: 5px;
}

.news__main__detail__icon>span {
    font-size: 26px;
}

.news__main__detail__line {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #ffffff;
    transform: translateX(-101%);
    transition: all 0.3s;
}

.news__main__detail__line--red {
    background-color: #cc0001;
}

.news__main__detail:hover .news__main__detail__line {
    transform: translateX(0);
}

.news__main__item {
    margin-bottom: 20px;
}

.news__main__item__detail {
    background-color: #ffffff;
    padding: 20px;
    border-bottom: 1px solid #dfdfdf;
}

.news__main__group__item__detail {
    padding: 20px 0;
}

.news__main__item__detail__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.news__main__item__detail__title~.news__main__item__detail__title {
    padding-top: 10px;
    border-top: 1px solid #dfdfdf;
    margin-top: 30px;
}

.news__main__item__detail__title>h4 {
    font: normal normal bold 18px/24px Open Sans;
    color: #1b1b1b;
}

.news__main__detail--red {
    color: #cc0001;
}

.news__main__item__background {
    height: 10px;
    background: transparent linear-gradient(270deg, #0099ff 0%, #cc0001 100%) 0% 0% no-repeat padding-box;
}

.news__main__item__detail__main {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    display: block;
}

.news__main__item__detail__main__img {
    width: 100%;
    height: 350px;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.news__main__item__detail__main__content {
    padding: 20px;
}

.news__main__item__detail__main__content>h3 {
    font: normal normal bold 16px/26px Open Sans;
    color: #1b1b1b;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 8px;
}

.news__main__item__detail__main__content>p {
    font: italic normal normal 14px/19px Open Sans;
    color: #acacac;
}

.news__main__item__detail__sub {
    display: flex;
    padding: 9px 0;
}

.news__main__item__detail__sub:not(.news__main__item__detail__sub:last-child) {
    border-bottom: 1px solid #dfdfdf;
}

.news__main__item__detail__sub-container {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    min-height: 100%;
}

.news__main__item__detail__sub__img {
    min-width: 140px;
    height: 140px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 14px;
}

.news__main__item__detail__sub__content {
    padding: 19px 0;
}

.news__main__item__detail__sub__content>p {
    font: italic normal normal 14px/19px Open Sans;
    color: #acacac;
}

.news__main__item__detail__sub__content>h3 {
    font: normal normal bold 15px/26px Open Sans;
    color: #1b1b1b;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* News Group */
.news-group {
    position: relative;
    margin-top: 10px;
    width: calc(100% - 330px);
    height: 670px
}

.news__main__group {
    padding: 0 20px;
    background-color: #ffffff;
}

.news__main__group:first-child {
    margin-bottom: 20px;
}

.news__main__group:last-child {
    background-color: #ffffff;
}

.news__main__pagination {
    background-color: #ffffff;
    padding: 20px;
    display: flex;
    justify-content: flex-end;
}



.news-group__main__item__detail__main__img {
    min-height: 350px;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.news-group__main__item__detail__main__content {
    padding: 0 30px;
}

.news-group__main__item__detail__main__content__time {
    font: italic normal normal 14px/19px Open Sans;
    color: #acacac;
    margin-top: 10px;
}

.news-group__main__item__detail__main__content>h3 {
    font: normal normal bold 18px/26px Open Sans;
    color: #cc0001;
    margin: 15px 0;
}

.news-group__main__item__detail__main__content__main {
    font: normal normal normal 16px/26px Open Sans;
    color: #1b1b1b;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 30px;
}

.news-group__main__item__detail__main__content__sub {
    font: normal normal normal 16px/26px Open Sans;
    color: #1b1b1b;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 57px;
}

.news-group__main__item__detail__main__detail {
    position: absolute;
    bottom: 0;
    left: 82%;
    display: inline-flex;
    font: italic normal normal 16px/22px Open Sans;
    color: #0099ff;
    overflow: hidden;
}

.news-group__main__item__detail__main__detail__line {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 100%;
    background-color: #0099ff;
    transform: translateX(-100%);
    transition: all 0.3s;
}

.news-group__main__item__detail__main__detail:hover .news-group__main__item__detail__main__detail__line {
    transform: translateX(0);
}

.news-group__main__item__detail__main__detail__icon>span {
    margin-right: 10px;
    font-size: 26px;
}

.news-group__main__item__detail__sub__img {
    max-height: 180px;
    max-width: 180px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 100%;
}

.news-group__main__item__detail__sub__content__main {
    font: normal normal normal 16px/26px Open Sans;
    color: #1b1b1b;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 57px;
    max-height: 49px;
}
.news-relative-container{
    position: absolute;
    top: 0px;
    right: 0px;
}
.news-relative-container .news__main__group{
    padding:0
}
.news-relative{
    position: absolute;
    top: 0px;
    right: 0px;
    width: 300px;
    height: 670px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    padding: 20px
}
.news-relative2{
    position: absolute;
    top: 690px;
    right: 0px;
    width: 300px;
    height: 700px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    padding: 20px

}
.news-relative2 h2, 
.news-relative h2{
    color: #CC0001
}
.news-container{
    min-height:1500px
}
.news__main__group__noImage{
    height: auto;
    border-bottom:1px solid rgba(0,0,0,0.1)
}
.news__main__group__noImage h3{
    padding-bottom:30px;
    overflow: hidden;
    text-overflow: ellipsis;
}
/* .ant-col.ant-col-xs-24.ant-col-sm-12.ant-col-md-12.ant-col-lg-12{ */
.news-list-group .news__main__group:first-child{
    height: 670px;
    display: flex;
    flex-direction: row;

}
.news-list-group .news__main__group{
    height: 700px;
    overflow: auto;
}
.news-list-group .news__main__group::-webkit-scrollbar{
    display: none;

}
.news-list-group .news__main__group:first-child .ant-row{
    display: block;
}
.news-list-group .news__main__group:first-child .ant-row .news-group__main__item__detail__main__content{
    min-width: 780px;
    padding: 16px;
}

/* NewsDetail */
.news-detail__main {
    background-color: #ffffff;
    color: #1b1b1b;
    padding: 20px;
    margin-bottom: 10px;
    box-shadow: 0px 3px 6px #00000029;
}

.news-detail__main>h1 {
    font: normal normal bold 20px/26px Open Sans;
    color: #cc0001;
}

.news-detail__main__img {
    height: 350px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 20px 0;
}

.news-detail__main__author {
    margin-top: 15px;
}

.news-detail__main__time {
    font: italic normal normal 14px/19px Open Sans;
    color: #acacac;
}

.news-detail__main__content {
    position: relative;
    max-width: 100%;
    font: normal normal normal 16px/26px Open Sans;
    color: #1b1b1b;
    overflow: hidden;
}

.news-detail__main__content * {
    float: none !important;
    max-width: 100% !important;
    width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
    position: relative !important;
    height: auto !important;
    left: unset !important;
    right: unset !important;
    top: unset !important;
    bottom: unset !important;
}

.news-group-button-back {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 185px;
    height: 40px;
    border: 1px solid #ffffff;
    border-radius: 60px;
    font: italic normal normal 16px/22px Open Sans;
    color: #ffffff;
    transition: all 0.3s;
}

.news-group-button-back:hover {
    border: 1px solid #cc0001;
    background-color: #cc0001;
    color: #ffffff;
}

.news-group-button-back__icon>span {
    font-size: 24px;
    transform: translateY(3px);
    margin-right: 2px;
}

.news-detail__sub {
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0px 3px 6px #00000029;
}

.news-detail__sub>h3 {
    font: normal normal normal 22px/30px Open Sans;
    color: #cc0001;
    text-transform: uppercase;
}

.news-detail__sub__item>h4 {
    font: normal normal bold 15px/26px Open Sans;
    margin-bottom: 20px;
}

.news-detail__sub__item {
    padding: 10px 0;
    border-bottom: 1px solid #dfdfdf;
    position: relative;
}

.news-detail__sub__item__time {
    font: italic normal normal 14px/19px Open Sans;
    color: #acacac;
}

.news-detail__main__button {
    margin-top: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 20px;
    border-radius: 40px;
    font: italic normal normal 16px/22px Open Sans;
    transition: all 0.3s;
    cursor: pointer;
    transition: all 0.3s;
    width: max-content;
}

.news-detail__main__button__icon {
    font-size: 34px;
}

.recruit-main__item__button.news-detail__sub__item__button {
    bottom: 0px;
    right: 0;
}
.news-generation__heading{
    position:relative;
    display: flex;
    margin-bottom:24px
}
.news-generation__heading1{
    position:relative;
    display: flex;
    margin-bottom:4px
}
.news-generation__heading2{
    position:relative;
    display: flex;
    margin-bottom:24px
}

.generation-rangedate__heading__item{
    display:flex;
    width: 290px;
height: 36px;
margin-left:-10px

}

.news-generation__heading1 label{
    line-height: 36px;
    color:#fff
    
}
.generation-rangedate__heading__item__input{
    height: 36px;
    line-height: 36px;



}
.generation-rangedate__heading__item .ant-picker.ant-picker-range{
    background: #FFFFFF 0% 0% no-repeat padding-box;
border-radius: 5px;
opacity: 1;
height:36px;
}
.news-generation-search__heading__item{
    right: 0px;
    background-color: rgb(255, 255, 255);
    background: '#FFFFFF 0% 0% no-repeat padding-box';
    border-radius: 5px;
    opacity: 1;
    position: absolute;
    width: 300px;
}
.news-generation-search__heading__item input{
    border:none;
    border-radius: 5px;
    font-size: 14px;
    padding:0 0 0 10px ;
    line-height: 36px;
    text-align: left;
font: italic normal normal 14px/34px Open Sans;
letter-spacing: 0px;
color: rgba(0, 0, 0, 0.85);
opacity: 1;
width:88%

}
.news-generation-search__heading__item button{
    border: none;
    background-color: #fff;
    color: #0099FF;
    border-radius: 5px;
    font-size: 18px;
    line-height: 36px;
    float: right;
    padding:0 8px
}

.generation-rangedate__heading__item{
    width:50%
}

.container-title{
    font-size: 30px;
text-align: left;
font: normal normal bold 22px/38px Open Sans;
letter-spacing: 0.44px;
color: #fff;
text-transform: uppercase;
}
.news-group__main__item__detail__main__content__noImage{
    padding:0;
    position: relative;
    height: auto;
    overflow: hidden;
    flex: 1;
}
.news-group__main__item__detail__main__content__noImage .news-group__main__item__detail__main__detail{
    position: absolute;
    left:66%;
    bottom:0
}
/* .news-group__main__item__detail__main__detail{
    right:0;
    position: absolute;
    display: inline;
} */
.news__main__item__detail.news__main__group__item__detail .ant-col.ant-col-xs-24.ant-col-sm-12.ant-col-md-12.ant-col-lg-12{
    position: relative;
}
.news__main__item__detail.news__main__group__item__detail .ant-col.ant-col-xs-24.ant-col-sm-12.ant-col-md-12.ant-col-lg-12 .news-group__main__item__detail__main__detail{
    left:82%;

    position: absolute;
}
.news-group-button-back{
    position: absolute;
    bottom: 20px;
    /* right: 50%;
    transform: translateX(50%) */
}

@media only screen and (min-width: 900px){
    .ant-col-lg-12 {
        display: block;
        flex: 0 0 50%;
        max-width: 100%;
    }
    .news-list-group .news__main__group:first-child{
        height: 670px;
        display: block;
        flex-direction: row;
    
    }
    .news-list-group .news__main__group:first-child .ant-row .news-group__main__item__detail__main__content{
        min-width: auto;
    }
    
    

}
@media only screen and (max-width: 768px) {
    .news__main__item__detail__sub-container {
        min-height: 461px;
        margin-top: 20px;
    }
 

}

@media only screen and (max-width: 900px) {
    .news-list-group .news__main__group:first-child .ant-row{
        display: flex;
    }
    .news__main__group__noImage .ant-row{
        display:block
    }
 .news__main__item__detail.news__main__group__item__detail .ant-col.ant-col-xs-24.ant-col-sm-12.ant-col-md-12.ant-col-lg-12 .news-group__main__item__detail__main__detail{
        left:70%
    }
    .news-list-group .news__main__group:first-child{
        height:auto
    }
    .news-group__main__item__detail__main__content__noImage .news-group__main__item__detail__main__detail{
        position: absolute;
        left:88%;
        bottom:0;
    }
    .news__main__item__detail__sub-container {
        min-height: 280px;
        /* height:80px; */
    }

    .news__main__item__detail__sub__img {
        min-width: 80px;
        height: 80px;
    }

    .news__main__item__detail__main__img {
        height: 200px;
    }

    .news__main__item__detail__title {
        flex-direction: column;
        align-items: start;
    }

    .news__main__detail--red {
        margin: 0;
    }

    .news-group__main__item__detail__main__content {
        padding: 4px;
    }

    .news-group__main__item__detail__main__detail {
        left: 88%;
    }
    .news-generation__heading1{
        height: 40px
    }
    .news-generation__heading{
        height: 90px

    }
    .news-generation-search__heading__item{
        width:100%;
        top:60px
    }
    .news-generation{
        padding-top:40px
    }
    .news-generation__heading2{
        margin-bottom: 8px;
    }
    .generation-search__heading__item{
        top:56px
    }
    .news-group{
        height:auto;
        width:100%;
        margin-bottom: -40px;
    }
    .news-list-group .news__main__group:first-child .ant-row .news-group__main__item__detail__main__content{
        min-width:0 !important;
        padding:0  !important;
    }
    .news-container{
        /* min-height:2500px!important; */
    }
    .news-relative-container{
        position: relative !important;
    }
    .news-relative{
        position: relative;
        width:100% !important;
        height:auto !important;
        margin-bottom:20px
    }
    .news-relative2{
        position: relative;
        width:100% !important;
        height:auto !important;
        top:0

    }
}
@media (min-width: 768px){
.ant-col-md-20 {
    display: block;
    flex: 0 0 83.33333333%;
    /* max-width: 83.33333333%; */
    min-width: 100%;
    margin-right: 0;
    width: 100%;
    
}

}
@media (max-width: 500px){
    .generation-rangedate__heading__item .ant-picker.ant-picker-range{
        margin-left: 0 !important;
    }
    .news__main__group__item__detail{
        flex: 1;
    }
    .generation-rangedate__heading__item{
        margin-left: 0;
    }
.news-group__main__item__detail__main__detail{
    left: 70%
}
.news-group__main__item__detail__main__content__noImage .news-group__main__item__detail__main__detail{
    left:70%
}
.recruit-main__item__button.news-detail__sub__item__button{
    position: absolute;
}
}