.admin-change-password-field__list{
    align-items: center;
}

.admin-change-password-field__list__item{
    font-size: 16px;
}

.admin-change-password-field__list__item__required{
    display: none;
    color:#CC0001;
}

.admin-change-password-field__list__item__required.active{
    display: inline;
}
