li,
ul,
p,
h1,
h2,
h3 {
    list-style: none;
    text-decoration: none;
    margin: 0;
    padding: 0;
}

a {
    color: #0099ff;
}

a:hover {
    color: #0467a8;
}

body {
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    box-sizing: border-box;
}

.container {
    max-width: 1335px;
    margin: 0 auto;
    padding: 0 80px;
}

.captcha {
    width: 100%;
    max-width: 300px;
}

.captcha>div>div {
    width: 100% !important;
    max-width: 300px !important;
}

.captcha>div iframe {
    width: 100% !important;
    max-width: 300px !important;
}

/* Header */
.header {
    position: relative;
}

.header__contain {
    display: flex;
}

.header__menu-mobile__list {
    height: 100vh;
    background-color: white;
    box-shadow: rgb(0 0 0 / 4%) 0px 3px 5px;
    overflow: scroll;
}

.header__menu-mobile__list__detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 30px;
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 1pt solid #DFDFDF;
    cursor: pointer;
}

.header__menu-mobile__list__detail.active {
    background-color: #EE0001;
    color: white;
}

.header__menu-mobile {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 110;
}

.header__menu-mobile__list__heading {
    background-color: rgba(0, 0, 0, 0.04);
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.header__menu-mobile__list__heading>img {
    width: 25px;
}

.header__menu-mobile__list__heading__item {
    padding: 10px 0;
    font-size: 14px;
}

.header__menu-mobile__list__child {
    display: none;
    background-color: rgba(0, 0, 0, 0.04);
    padding: 21px 0px 23px 44px;
}

.header__menu-mobile__list__child.active {
    display: block;
}

.header__menu-mobile__list__child__item {
    display: block;
    padding: 10px 0;
    color: rgba(0, 0, 0, 0.85);
}

.header__logo {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
}

.header__logo img {
    width: 112px;
}

.header__content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.header__content__heading {
    background: rgb(204, 0, 1, 0.5) 0% 0% no-repeat padding-box;
    color: white;
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.header__content__heading__item__fake {
    position: absolute;
    top: 0;
    left: 100%;
    background: rgb(204, 0, 1, 0.5) 0% 0% no-repeat padding-box;
    /* width: auto; */
    height: 100%;
}

.header__content__heading__content {
    color: #ffffff;
}

.header__content__heading img {
    width: 30px;
}

.header__content__heading__item {
    padding: 0 5px;
    margin: 5px 0;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 15px;
    color: white;
}

.header__content__heading__item:hover {
    color: #cc0001;
}

/* .header__content__heading__item~.header__content__heading__item::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 60%;
    background-color: white;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    border-left: 1px solid white;
} */

.header__content__category {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 16px;
    font: normal normal bold 16px/22px Open Sans;
    background-color: white;
    padding: 0 10px;
}

.header__content__category__item-container {
    position: relative;
    height: 100%;
}

.header__content__category__item-container:hover:after {
    display: block;
}

.header__content__category__item-container:hover .header__content__category__child {
    display: block;
}

.header__content__category__item-container:hover .header__content__category__item {
    color: #EE0001;
}

.header__content__category__item {
    padding: 0 12px;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    width: max-content;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
}
.header__content__category__item p{
   margin: 0;
}

.header__content__category__item:hover {
    color: #EE0001;
}

.header__content__category__item>p {
    margin-right: 8px !important;
}

.header__content__category__child {
    position: absolute;
    top: 100%;
    left: 0;
    box-shadow: 0px 3px 6px #0000004d;
    display: none;
    z-index: 50;
}

.header__content__category__child--last {
    left: unset;
    right: 0;
}

.header__content__category__item-container::after {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8px;
    content: "";
    background-color: #EE0001;
}

.header__content__category__child__item {
    display: block;
    width: 300px;
    /* width: max-content; */
    color: #EE0001;
    padding: 15px 0 15px 20px;
    background-color: white;
    font-size: 16px;
    font-weight: 500;
}
.header__content__category__child__item p {
    margin: 0;
}

.header__content__category__child__item:hover {
    background-color: #EE0001;
    color: white;
}

.header__content__category__child__item:hover p {
    transform: translateX(10px);
}

.header__content__category__item.active {
    color: #EE0001;
}

.header__content__category .active {
    position: relative;
}

.header__content__category .active::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8px;
    background-color: #EE0001;
}

.header__content__category__item--mobile {
    display: none;
    font-size: 30px;
}

.header__content__category__item--mobile:hover {
    color: #EE0001;
}

.header__content__category__item--mobile--small {
    display: flex;
    align-items: center;
    background-color: white;
    margin: 0 20px;
    display: none;
}

.header__content__category__item--mobile--small span {
    font-size: 20px;
}

.header__content__category__item--mobile--small:hover span {
    color: #EE0001;
}

.header__sub-menu-container {
    position: relative;
}

.header__sub-menu-item {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 50;
}

.header__sub-menu-container:hover .header__sub-menu-item {
    display: block;
}

/* Header Home Page */
.header__home {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    transition: top 0.2s ease-in-out;
}

.header__home--white {
    top: 0;
    background-color: #fff;
    box-shadow: 0px 3px 6px #0000001A;
}

.header__home--hide {
    top: -100%
}

.header__home .header__logo {
    background: transparent;
}

.header__home .header__content__category {
    background: transparent;
}

/* Header Mobile Tablet */
.header__home__mobile--white {
    background-color: #fff;
}

.header__content__category__item--mobile {
    display: none;
    font-size: 30px;
    color: #1B1B1B;
}

.header__content__heading__item img {
    width: 30px;
}

.header__content__category__item--mobile .header__content__heading__item {
    color: inherit;
    padding: 0;
    margin-right: 20px;
}

.header__mobile__sub-menu-container .header__menu-mobile__list__child__item {
    padding-right: 30px;
}

/* Footer */
.footer {
    padding: 50px 0 0;
    position: relative;
    background-size: cover;
}

.footer__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f5f5f5 0% 0% no-repeat padding-box;
    opacity: 0.9;
}

.footer__main {
    position: relative;
    /* display: grid;
    grid-template-columns: 25% 25% 25% 25%; */
    border-bottom: 1px solid #707070;
    padding-bottom: 26px;
}

.footer__main__item a {
    display: block;
    margin: 8px 0;
}

.footer__main__item h3 {
    color: #cc0001;
    font-size: 18px;
    margin: 10px 0;
    font-weight: bold;
}

.footer__main__item p {
    margin: 5px 0;
    font-weight: 500;
}

.footer__policy {
    background: #000000 0% 0% no-repeat padding-box;
    padding: 18px 0;
}

.footer__policy__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #e5e7ea;
    flex-wrap: wrap;
    text-align: left;
}

.footer__policy__main__detail {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-right: 120px;
}

.footer__policy__main__detail>a {
    font-size: 14px;
    font-weight: 300;
    color: #e5e7ea;
}

.footer__policy__main h2 {
    font-size: 15px;
    color: #e5e7ea;
    margin-right: 10px;
}

.footer__ending {
    padding: 28px 0;
    position: relative;
}

.footer__ending__item {
    display: flex;
    align-items: center;
    height: 100%;
}

.footer__ending__item--media {
    justify-content: center;
}

.footer__ending__item__logo {
    margin-right: 10px;
}

.footer__ending__item__logo>img {
    width: 100px;
}

.footer__ending__item__detail {
    margin-bottom: 10px;
}

.footer__ending__item__detail__item {
    display: flex;
    align-items: center;
    font: normal normal normal 16px/30px Open Sans;
}

.footer__ending__item__detail__item>i {
    margin-right: 10px;
    font-size: 16px;
}

.footer__ending__item__title {
    font: normal normal bold 16px/30px Open Sans;
    color: #1b1b1b;
    text-transform: uppercase;
}

.footer__ending__item__icon {
    border: 2px solid #0099ff;
    color: #0099ff;
    width: 44px;
    height: 44px;
    border-radius: 100%;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 6px;
    transition: all 0.3s;
    cursor: pointer;
}

.footer__ending__item__icon:hover {
    background-color: #0099ff;
    color: #ffffff;
}

.footer__main__item__sub {
    color: #0099ff;
    cursor: pointer;
}

.footer__main__item__sub__title {
    display: flex;
}

.footer__main__item__sub__title>p {
    width: 60%;
}

.footer__main__item__sub__title>span {
    margin: 8px 4px 0 0;
}

.footer__main__item__sub__item {
    /* margin-left: 20px; */
    display: none;
}

.footer__main__item__sub__item.active {
    display: block;
}

.footer__item-media__icon {
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border: 2px solid #0099ff;
    margin-left: 10px;
    transition: all 0.3s;
}

.footer__item-media__icon:hover {
    color: white;
    background-color: #0099ff;
}

.footer__item-media__icon>i {
    font-size: 20px;
}

/* Footer Mobile */
.footer__mobile {
    display: none;
    position: relative;
    text-align: left;
}

.footer__mobile__nav {
    padding: 40px 70px;
    border-bottom: 1px solid #707070;
}

.footer__mobile__nav__main {
    display: block;
    font: normal normal bold 18px/24px Open Sans;
    color: #cc0001;
    padding: 10px 0;
}

.footer__mobile__nav__main:active {
    color: #cc0001;
}

.footer__mobile__nav__sub {
    font: normal normal normal 16px/30px Open Sans;
    color: #0099ff;
    display: block;
}

.footer__mobile__nav__main:not(:last-child) {
    border-bottom: 1px solid #ACACAC;
}

.footer__mobile .ant-collapse:not(:last-child)>.ant-collapse-item {
    border-bottom: 1px solid #ACACAC;
}

.footer__mobile .footer__mobile__nav .ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header {
    padding: 0;
    color: inherit;
}

.footer__mobile .footer__mobile__nav .ant-collapse-content>.ant-collapse-content-box {
    padding: 15px 0 0 0;
}

.footer__mobile__company {
    display: flex;
    position: relative;
    padding: 25px 0;
    column-gap: 22px;
    border-bottom: 1px solid #707070;
}

.footer__mobile__company img {
    width: 100px;
    height: 100px;
}

.footer__mobile__company__info>h4 {
    font-size: 14px;
    font-weight: bold;
    line-height: 30px;
}

.footer__mobile__company__info>p {
    margin-top: 12px;
}

.footer__mobile__company__info>p>i {
    margin-right: 10px;
}

.footer__mobile__company__info__flex {
    display: flex;
    align-items: flex-start;
}

.footer__mobile__company__info__flex>i {
    margin-top: 6px;
}

.footer__mobile__media {
    display: flex;
    justify-content: flex-end;
    padding: 15px 0;
}

.footer__mobile__nav__sub__sub {
    padding-left: 5px;
}

.footer__mobile__company {
    display: flex;
    position: relative;
    padding: 25px 0;
    column-gap: 22px;
    border-bottom: 1px solid #707070;
}

.footer__mobile__company img {
    width: 100px;
    height: 100px;
}

/* TitleBreadcrum */
.title-breadcrum {
    padding: 36px 0;
    margin-top: 10px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
}

.title-breadcrum__overlay {
    background: transparent linear-gradient(270deg, #82cdff00 0%, #0099ff 75%) 0% 0% no-repeat padding-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.title-breadcrum h1 {
    font-size: 28px;
    color: #ffffff;
    font-weight: bold;
    text-transform: uppercase;
}

.title-breadcrum__main {
    position: relative;
    display: flex;
    align-items: center;
}

.title-breadcrum__main__circle {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 10px solid #ffffff;
    margin-right: 10px;
    flex-shrink: 0;
}

/* Unit */
.home__main__title__main.home__main__title--unit {
    font-size: 18px;
    color: #1b1b1b;
    margin: 0;
}

.home__main__unit__item>img {
    width: 100%;
    margin: 0 auto;
    object-fit: contain;
}

/* Register */
.home__main__register {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
}

.home__main__child--register {
    padding: 21px;
}

.home__main__child--register p {
    font-size: 18px;
    color: #ffffff;
    margin: 0 30px;
}

.home__main__register__input {
    background-color: white;
    padding: 4px 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.home__main__register__input i {
    font-size: 20px;
    color: #cc0001;
}

.home__main__register__input input {
    outline: none;
    border: none;
    font-size: 18px;
    width: 330px;
    color: #acacac;
    font: normal normal 300 16px/30px Open Sans;
}

/* Breadcrum */
.breadcrum__main {
    display: flex;
    flex-wrap: wrap;
}

.breadcrum__main a>i {
    color: #acacac;
}

.breadcrum__main__item span:first-child {
    margin: 0 2px;
    font-size: 12px;
}

.breadcrum__main__item--sub {
    color: #acacac;
    font: normal normal normal 14px/20px Open Sans;
}

.breadcrum__main__item--main {
    color: #1b1b1b;
    font: normal normal normal 14px/20px Open Sans;
}

.breadcrum__main__item {
    max-width: max-content;
}

/* MessageContact */
.message-contact {
    position: fixed;
    right: 10px;
    top: 70%;
    transform: translateY(-50%);
    z-index: 100;
}

.message-contact__item {
    width: 50px;
    height: 50px;
    border: 1px solid #cc0001;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    cursor: pointer;
}

.message-contact__item--phone {
    display: none;
}

.message-contact__item:hover {
    background-color: #cc0001;
}

.message-contact__item:hover>i {
    color: white;
}

.message-contact__item:nth-child(2) {
    background-color: #cc0001;
}

.message-contact__item:nth-child(2)>i {
    color: white;
}

.message-contact__item>i {
    font-size: 24px;
    color: #cc0001;
}

/* ButtonDetail */
/* Pagination */
.pagination-main .ant-pagination-options {
    display: none;
}

.pagination-main .ant-pagination-item-active {
    border-radius: 100%;
}

.pagination-main .ant-pagination-item-link {
    border: none;
}

.pagination-main .ant-pagination-item:not(.ant-pagination-item-active) {
    border: none;
}

.flex {
    display: flex;
}

.justify-between {
    justify-content: space-between;
}

.justify-center {
    justify-content: center;
}

.item-center {
    align-items: center;
}

.mr-8 {
    margin-right: 8px !important;
}

.cursor-pointer {
    cursor: pointer;
}

.transform-unset {
    transform: unset !important;
}

.translate-x-8 {
    transform: translateX(8px);
}

.date-picker-custom > .ant-picker-input > input {
    text-align: center;
}

/* Tablet */
@media only screen and (max-width: 1200px) {
    .header__content__heading {
        display: none;
    }

    .header__content__category {
        display: none;
    }

    .header__content__category__item {
        display: none;
    }

    .header__content__category__item--mobile {
        display: flex;
        height: 100%;
        justify-content: flex-end;
        align-items: center;
    }

    .header__logo img {
        width: 80px;
    }

    .footer {
        padding: 0;
    }

    .footer__desktop {
        display: none;
    }

    .footer__mobile {
        display: block;
    }
}

@media only screen and (max-width: 1024px) {
    .container:not(.header) {
        padding: 0 20px;
    }

    /* .generation-sort__heading {
        flex-direction: column-reverse;
    } */
    .generation-sort_heading__item__input>input {
        width: unset !important;
    }

    .generation-sort_heading__item__input {
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 767px) {
    .header__home .container {
        padding: 0;
    }

    .header__content__category__item--mobile {
        font-size: 15px;
    }

    .header__content__category__item--mobile .menu__icon {
        font-size: 20px;
    }

    .header__menu-mobile__list__detail {
        padding: 14px 15px;
        font-size: 16px;
    }

    .header__menu-mobile__list__child {
        padding: 21px 0px 20px 36px;
    }

    .header__menu-mobile__list__child__item {
        font-size: 14px;
    }

    .header__mobile__sub-menu-container .header__menu-mobile__list__child__item {
        padding-right: 15px;
    }

    .header__logo img {
        width: 40px;
    }

    .footer__mobile__nav {
        padding: 25px 5px;
    }

    .footer__mobile__nav__main {
        font-size: 16px;
    }

    .footer__mobile__nav__sub {
        font-size: 14px;
    }

    .footer__mobile__company {
        column-gap: 10px;
    }

    .footer__mobile__company img {
        width: 80px;
        height: 80px;
    }

    .footer__mobile__company__info>h4 {
        font-size: 12px;
        line-height: 20px;
    }

    .footer__mobile__company__info>p {
        font-size: 12px;
    }

    .footer__policy__main h2 {
        font-size: 12px;
        line-height: 18px;
    }
}

@media only screen and (max-width: 650px) {
    .header {
        padding: 0;
    }

    .footer__ending__item:not(.footer__ending__item--media) {
        display: block;
    }

    .footer__ending__item__detail {
        text-align: center;
    }

    .footer__ending__item__detail__item {
        align-items: flex-start;
        justify-content: center;
    }

    .footer__ending__item__detail__item>i {
        margin-top: 6px;
    }

    .footer__ending__item__logo {
        text-align: center;
    }
}

@media only screen and (max-width: 575px) {
    .container:not(.header) {
        padding: 0 12px;
    }

    .footer__main {
        grid-template-columns: 100%;
        text-align: center;
    }

    .footer__main {
        grid-template-columns: 100%;
        text-align: center;
    }

    .header__content__heading {
        display: none;
    }

    .header__content__category__item--mobile--small {
        display: flex;
    }

    .footer__policy__main__detail {
        margin: 0;
    }

    .title-breadcrum {
        padding: 20px 0;
    }

    .title-breadcrum__main>h1 {
        font-size: 22px;
    }

    .message-contact__item {
        width: 35px;
        height: 35px;
    }

    .message-contact__item>i {
        font-size: 18px;
    }

    /* .generation-sort__heading__item:last-child > span {
        display: none;
    } */
    .generation-sort__heading__item:last-child {
        margin-left: 0;
    }

    .generation-sort-detail__heading {
        flex-direction: column;
    }

    .message-contact__item--mail {
        display: none;
    }

    .message-contact__item--phone {
        display: flex;
    }

    .footer__main__item__sub__title {
        justify-content: center;
    }

    .footer__main__item__sub__title>p {
        width: unset;
    }
}

/* generation sort */
.generation-sort__heading {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.generation-sort__heading__item {
    display: flex;
    align-items: center;
    /* margin-left: 20px; */
}

.generation-sort-detail__heading {
    justify-content: space-between;
}

.generation-sort-detail__heading__item {
    margin: 0;
}

.generation-sort__heading__item>h2 {
    font: normal normal bold 22px/38px Open Sans;
    color: #ffffff;
    text-transform: uppercase;
}

.generation-sort_heading__item__input {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-radius: 25px;
    overflow: hidden;
}

.generation-sort_heading__item__input>input {
    border: none;
    padding: 6px 15px;
    width: 385px;
    outline: none;
    color: rgba(0, 0, 0, 0.85);
}

.generation-sort_heading__item__input__icon {
    color: #0099ff;
    padding: 2px 15px;
    font-size: 16px;
    border-left: 1px solid #acacac;
    cursor: pointer;
}

.generation-sort__heading__item>span {
    margin-right: 10px;
    color: #d6efff;
    font-size: 16px;
}