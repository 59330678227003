/* IntroPage */
.intro {
    position: relative;
}

.intro__main {
    margin-top: 60px;
}

.intro__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 85%;
    background: transparent linear-gradient(289deg, #82cdff 0%, #00558d 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
}

.intro__main__item-container {
    margin-bottom: 50px;
    display: block;
}

.intro__main__item-container p {
    color: #ffffff;
    text-transform: uppercase;
}

.intro__main__item-container>p {
    font: normal normal bold 18px/20px Open Sans;
    color: #ffffff;
    text-transform: uppercase;
    line-height: 22px;
    margin-top: 10px;
}

.intro__main__item {
    position: relative;
    height: 250px;
    border-radius: 5px;
    overflow: hidden;
    /* background-position: top left; */
    background-size: cover;
    background-repeat: no-repeat;
}

.intro__main__item:hover .intro__main__item--hover {
    opacity: 1;
}

.intro__main__item__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent linear-gradient(124deg, #0099ff 0%, #cc0001 100%) 0% 0% no-repeat padding-box;
    opacity: 0.6;
}

.intro__main__item__main {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    display: flex;
    align-items: center;
}

.intro-detail__container {
    display: flex;
    flex-direction: column;
}

.intro-detail__main {
    background-color: white;
    color: black;
    padding: 50px;
}

.intro__main__item__main__detail {
    width: 220px;
    color: #ffffff;
}

.intro__main__item__main__detail>p {
    font-size: 30px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    margin-top: 10px;
}

.intro__main__item__main__detail__child {
    display: flex;
    /* align-items: center; */
    font-size: 16px;
    position: relative;
    position: relative;
    width: max-content;
    overflow: hidden;
}

.intro__main__item__main__detail__child:hover .intro__main__item__main__detail__line {
    transform: translateX(0);
}

.intro__main__item__main__detail__child>span {
    font-size: 25px;
}

.intro__main__item__main__detail__line {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: #ffffff;
    transform: translateX(-100%);
    transition: all 0.3s;
}

.intro__main__item--hover {
    transition: all 0.3s;
    opacity: 0;
}

/* IntroGeneration */
.intro-generation {
    position: relative;
    padding: 60px 0;
    color: #ffffff;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

.intro-generation__title {
    padding: 0 0 60px;
    font: normal normal bold 24px/38px Open Sans;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
}

.intro-generation__overlay {
    background: transparent linear-gradient(180deg, #00558d 0%, #82cdff 100%) 0% 0% no-repeat padding-box;
    opacity: 0.9;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* IntroAbout */
.intro-about__heading {
    padding-bottom: 40px;
    height: max-content;
    position: relative;
    display: flex;
    flex-wrap: wrap;
}

.intro-about__heading__item {
    width: 50%;
    min-height: 450px !important;
}

.intro-about__heading__title {
    margin-bottom: 20px;
    font-size: 24px;
    color: #ffffff;
    font-weight: bold;
}

.intro-about__heading__main {
    font-size: 16px;
}

.intro-about__heading__main table {
    table-layout: fixed;
}

.intro-about__heading__main th,
.intro-about__heading__main td {
    text-align: left;
    padding: 8px 0;
    word-wrap: break-word;
}

.intro-about__heading__main td {
    padding-right: 30px;
    width: max-content;
    display: flex;
}

.intro-about__content {
    margin-top: auto;
    position: relative;
    background: #ffffff 0% 0% no-repeat padding-box;
    padding: 0 100px 0;
    margin: 94px 0 135px;
    color: rgba(0, 0, 0, 0.85);
}

.intro-about__content__fake-bg-1 {
    position: absolute;
    top: -100px;
    left: 100px;
    height: 100px;
    width: calc(100% - 100px);
    background-color: #ffffff;
}

.intro-image__container {
    position: relative;
}

.intro-image__container img {
    height: auto !important;
}

.intro-image__container table {
    table-layout: fixed;
    width: 90%;
}

.intro-image__container table td,
.intro-image__container table th {
    word-wrap: break-word;
}

.intro-about__content__fake-bg-2 {
    position: absolute;
    bottom: -100px;
    right: 100px;
    height: 100px;
    width: calc(100% - 100px);
    background-color: #ffffff;
}

.intro-about__content>p {
    position: relative;
    z-index: 11;
}

.intro-about__content__img {
    position: absolute;
    right: 60px;
    bottom: calc(100% + 45px);
    width: 430px;
    height: 540px;
    background-size: cover;
    box-shadow: 0px 4px 8px #0000004d;
    z-index: 20;
    background-repeat: no-repeat;
    background-position: top;
}

.intro-about__content>p {
    margin-bottom: 18px;
    /* text-align: justify; */
}

.intro-about__content::after {
    content: "";
    border-width: 50px 50px;
    border-style: solid;
    border-color: transparent white white transparent;
    position: absolute;
    top: -100px;
    left: 0px;
    z-index: 10;
}

.intro-about__content::before {
    content: "";
    border-width: 50px 50px;
    border-style: solid;
    border-color: white transparent transparent white;
    position: absolute;
    bottom: -100px;
    right: 0;
    z-index: 10;
}

.intro-about__content__strong {
    color: #cc0001;
    font-weight: bold;
    position: relative;
    z-index: 11;
}

.intro__main__button-container {
    position: relative;
    display: flex;
    align-items: center;
    /* justify-content: flex-end; */
    margin-top: 20px;
}

.intro__main__button-container--intro-field {
    justify-content: center;
}

.intro-organize__main>img {
    width: 22% !important;
    height: auto !important;
}

.intro-image__container table {
    margin-top: 10px;
    table-layout: fixed;
    text-align: center;
}


@media only screen and (max-width: 1300px) {
    .intro-about__heading__item {
        width: 100%;
        min-height: 450px !important;
    }

    .intro-image__container table th,
    .intro-image__container table td {
        display: block;
    }

    .intro-image__container table img {
        width: 60% !important;
    }

    .intro-image__container img {
        /* width: 60% !important;
        height: auto !important; */
    }

    .intro-detail__main {
        padding: 30px;
    }

    .intro-about__heading-fake {
        height: 500px;
    }

    .intro-about__content__img {
        left: 50%;
        transform: translateX(-50%);
        right: unset;
        width: 450px;
    }
}

/* Intro Organization */
.intro-organize {
    position: relative;
    text-align: left;
}

.intro-organize__main__title {
    width: 400px;
    background: transparent linear-gradient(278deg, #cc0001 0%, #00558d 100%) 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 15px;
    font-size: 20px;
    display: flex;
    align-items: center;
    margin: 40px 0 20px;
    text-transform: uppercase;
}

.intro-organize__main__title>h3 {
    color: #ffffff;
}

.intro-organize__main__title-long {
    width: 100%;
}

.intro-organize__main__title__circle {
    min-width: 25px;
    height: 25px;
    border: 8px solid #ffffff;
    margin-right: 10px;
    border-radius: 100%;
}

.intro-organize__main {
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #0000004d;
    border-radius: 5px;
}

.intro-organize__main>img {
    width: 100% !important;
    height: auto !important;
}

.intro-detail__main__decor {
    background: transparent linear-gradient(270deg, #0099FF 0%, #CC0001 100%) 0% 0% no-repeat padding-box;
    height: 10px;
}

/* Intro Agency */
.intro-agency {
    position: relative;
    text-align: center;
}

.intro-agency__main {}

.intro-agency__main__item__logo {
    display: flex;
    align-items: center;
    background-color: white;
    text-align: start;
    border-radius: 5px;
    color: #1b1b1b;
    padding: 18px 25px;
    min-height: 240px;
    height: 100%;
}

.intro-agency__main__item__detail {
    flex: 1;
}

.intro-agency__main__item__detail>h3 {
    font: normal normal bold 22px/25px Open Sans;
    color: #cc0001;
    text-transform: uppercase;
    padding-bottom: 14px;
    border-bottom: 1px solid #1b1b1b;
}

.intro-agency__main__item__logo__main {
    width: 175px;
    padding: 0 20px;
    margin: 0 auto;
}

.intro-agency__main__item__logo__item-logo {
    text-align: center;
}

.intro-agency__main__item__logo .intro-agency__main__item__child__item {
    font: normal normal normal 20px/25px Open Sans;
}

.intro-agency__main__item h3 {
    font: normal normal bold 16px/25px Open Sans;
    color: #cc0001;
    text-transform: uppercase;
    padding-bottom: 14px;
    border-bottom: 1px solid #dfdfdf;
}

.intro-agency__label {
    background: #00558d 0% 0% no-repeat padding-box;
    text-align: center;
    font: normal normal bold 24px/33px Open Sans;
    color: #ffffff;
    text-transform: uppercase;
    padding: 20px 10px;
    margin-bottom: 50px;
    position: relative;
}

.intro-agency__label::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    border: 20px solid transparent;
    border-top-color: #3986b8;
}

.intro-agency__map {
    margin-bottom: 80px;
    position: relative;
    overflow: hidden;
}

.intro-agency__map__image>img {
    width: 100%;
}

.intro-agency__map__image {
    position: relative;
    max-width: 436px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
}

.intro-agency__main__col {
    margin-bottom: 10px;
}

.intro-agency__main-container {
    margin-bottom: 30px;
}

.intro-agency__map__image__dots {
    position: absolute;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 100%;
    border: 4px solid rgba(255, 255, 255, 0.4);
    width: 20px;
    height: 20px;
    transition: all 0.3s;
}

.intro-agency__map__image__dots__line-right {
    position: absolute;
    left: 100%;
    bottom: 100%;
    width: 500px;
    height: 70px;
    display: flex;
}

.intro-agency__map__image__dots__line-left {
    position: absolute;
    right: 100%;
    bottom: 100%;
    width: 500px;
    height: 70px;
    display: flex;
}

.intro-agency__map__image__dots__line__cross-top {
    background: linear-gradient(to top left,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) calc(50% - 1.2px),
            #ffffff 50%,
            rgba(0, 0, 0, 0) calc(50% + 1.2px),
            rgba(0, 0, 0, 0) 100%);
}

.intro-agency__map__image__dots__line__cross-bottom {
    background: linear-gradient(to top right,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) calc(50% - 0.8px),
            #ffffff 50%,
            rgba(0, 0, 0, 0) calc(50% + 0.8px),
            rgba(0, 0, 0, 0) 100%);
}

.intro-agency__map__image__dots__line__cross {
    width: 20%;
    height: 100%;
}

.intro-agency__map__image__dots__line__square {
    height: 100%;
    flex: 1;
}

.intro-agency__map__image__dots__line__square-top {
    border-top: 1px solid #ffffff;
}

.intro-agency__map__image__dots__line__square-bottom {
    border-bottom: 1px solid #ffffff;
}

.intro-agency__main__item {
    background-color: white;
    text-align: start;
    border-radius: 5px;
    color: #1b1b1b;
    padding: 18px 25px;
    min-height: 240px;
    height: 100%;
}

.intro-agency__main__item--img {
    height: 100%;
    background-size: cover;
    border-radius: 5px;
    background-repeat: no-repeat;
    /* background-position:top; */
    min-height: 240px;
}

.intro-agency__main__item-main {
    margin-top: 14px;
}

.intro-agency__main__item__child {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

.intro-agency__main__item__child__logo {
    justify-content: flex-start;
    column-gap: 100px;
}

.intro-agency__main__item__child__logo__item~.intro-agency__main__item__child__logo__item {
    margin-left: 0;
}

.intro-agency__main__item__decor {
    height: 10px;
    background: transparent linear-gradient(270deg, #0099ff 0%, #cc0001 100%) 0% 0% no-repeat padding-box;
    margin-bottom: 10px;
}

.intro-agency__main__item__child__item {
    display: flex;
    align-items: center;
    margin: 2px 0;
    color: #1b1b1b;
}

.intro-agency__main__item__child__item__icon-fake {
    color: white;
}

.intro-agency__main__item__child__item i {
    margin: 3px 8px 0 0;
}

.intro-agency__main__item__child__item~.intro-agency__main__item__child__item {}

.intro-agency__map__area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.intro-agency__map__area__main {
    min-width: 170px;
    position: absolute;
    /* top:0;
    left:0; */
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 10px 20px;
    text-transform: uppercase;
    color: #00558d;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.3s;
}

.intro-agency__map__area__main__all {
    min-width: 170px;
    position: absolute;
    /* top:0;
    left:0; */
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 10px 20px;
    text-transform: uppercase;
    color: #00558d;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.3s;
}

.intro-agency__map__area__main:hover {
    background-color: #00558d;
    color: white;
}

/* Intro Field */
.intro-field {
    position: relative;
}

.intro-field__list {
    justify-content: center;
}

.intro-field__item {
    background-color: #ffffff;
    color: #1b1b1b;
    margin-bottom: 30px;
    box-shadow: 0px 3px 6px #0000004d;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
}

.intro-field__item__background-container {
    overflow: hidden;
    width: 100%;
    height: 240px;
    position: relative;
}

.intro-field__item__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transition: all 0.3s;
}

.intro-field__item:hover .intro-field__item__background {
    transform: scale(1.3);
}

.intro-field__item__detail {
    padding: 15px;
    display: flex;
    font-size: 16px;
}

.intro-field__item__detail__circle {
    width: 25px;
    height: 25px;
    border: 8px solid #0099ff;
    border-radius: 100%;
    margin-right: 10px;
}

.intro-field__item__detail__title {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Intro Modal */
.intro-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
}

.intro-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #1b1b1b;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    overflow: hidden;
    width: 80%;


}

.intro-modal__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
    border: 1px solid #dfdfdf;
}

.intro-modal__item {
    display: flex;
    align-items: center;
}

.intro-modal__item h2 {
    font: normal normal bold 22px/30px Open Sans;
    text-transform: uppercase;
}

.intro-modal__item>span {
    font-size: 24px;
    color: #707070;
}

.intro-modal__item>span:hover {
    color: #0099ff;
}

.intro-modal__heading__circle {
    width: 30px;
    height: 30px;
    border: 9px solid #0099ff;
    border-radius: 100%;
    margin-right: 10px;
}

.intro-modal__main {
    padding: 20px 30px;
    overflow-y: auto;
    max-height: 80vh;
}

.intro-modal__main__content {
    margin-bottom: 20px;
    font: normal normal normal 18px/30px Open Sans;
    color: #1b1b1b;
}

.intro-modal__main__detail {
    text-align: center;
    font: italic normal normal 16px/30px Open Sans;
    margin: 10px 0;
}

.intro-modal__main__img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.intro-modal__main__img {
    height: 300px;
    width: 450px;
    margin: 12px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.intro-modal--background {
    background: transparent linear-gradient(270deg, #0099ff 0%, #cc0001 100%) 0% 0% no-repeat padding-box;
    height: 10px;
}



/* Intro Achieve */
.intro-achieve {
    position: relative;
}

.intro-achieve__main {
    color: #1b1b1b;
    margin-bottom: 60px;
}

.intro-achieve__main__title {
    width: 400px;
    background: transparent linear-gradient(278deg, #cc0001 0%, #00558d 100%) 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 15px;
    font-size: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.intro-achieve__main__title>h3 {
    color: #ffffff;
}

.intro-achieve__main__title__circle {
    width: 25px;
    height: 25px;
    border: 8px solid #ffffff;
    margin-right: 10px;
    border-radius: 100%;
}

.intro-achieve__main__detail {
    background-color: #ffffff;
    padding: 30px 60px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    /* justify-content: center; */
}

.intro-achieve__main__detail--sub {
    background-color: #ffffff;
    padding: 30px 75px;
}

.intro-achieve__main__detail--sub>p {
    margin-bottom: 10px;
}

.intro-achieve__main__detail--sub h4 {
    font: normal normal normal 18px/40px Open Sans;
    color: #cc0001;
    margin-bottom: 0;
}

.intro-achieve__main__detail--sub h3 {
    font: normal normal normal 24px/40px Open Sans;
    color: #cc0001;
    margin-bottom: 20px;
}

.intro-achieve__main__detail>img {
    margin: 10px 20px;
    width: 130px;
}

.intro-achieve__main__detail__background {
    background: transparent linear-gradient(270deg, #0099ff 0%, #cc0001 100%) 0% 0% no-repeat padding-box;
    height: 10px;
    width: 100%;
}

.intro-achieve__item {
    text-align: center;
}

.intro-achieve__item>p {
    width: 80%;
    margin: 10px auto;
}

.intro-achieve>p {
    margin-bottom: 20px;
}

.intro-achieve__img {
    width: 100%;
}

.intro-achieve__title {
    position: absolute;
    bottom: calc(100% - 55px);
    left: 50%;
    transform: translateX(-50%);
    background: #cc0001 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000004d;
    border-radius: 10px;
    padding: 20px 118px;
    /* width: max-content; */
    text-align: center;
    color: #ffffff;
    width: calc(100% - 200px);
}

.intro-achieve__title p:first-child {
    font-size: 18px;
}

.intro-achieve__title p:last-child {
    font-size: 24px;
}

/* Button Intro */
.intro__main__button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 20px;
    border-radius: 40px;
    font: italic normal normal 16px/22px Open Sans;
    transition: all 0.3s;
    cursor: pointer;
    transition: all 0.3s;
    width: max-content;
}

.intro__main__button--white {
    border: 1px solid white;
    color: white;
}

.intro__main__button--white:hover {
    /* color:#cc0001; */
    background-color: #cc0001;
}

.intro__main__button--red {
    background-color: #cc0001;
    color: white;
    width: 170px;
    height: 40px;
}

.intro__main__button--red:hover {
    background-color: transparent;
    border: 1px solid white;
    color: white;
}

.intro__main__button__icon {
    font-size: 34px;
}

/* Intro Value */
.intro-value {
    position: relative;
}

.intro-value__main__title {
    width: 400px;
    background: transparent linear-gradient(278deg, #cc0001 0%, #00558d 100%) 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 15px;
    font-size: 20px;
    display: flex;
    align-items: center;
    margin: 60px 0 20px;
}

.intro-value__main__title>h3 {
    color: #ffffff;
}

.intro-value__main__title__circle {
    width: 25px;
    height: 25px;
    border: 8px solid #ffffff;
    margin-right: 10px;
    border-radius: 100%;
}

.intro-value__title {
    text-align: center;
}

.intro-value__main {
    color: #1b1b1b;
}

.intro-value__main__detail {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000004d;
    border-radius: 5px;
    padding: 30px 100px;
}

.intro-value__main__detail>img {
    width: 100%;
}

.intro-value__main__decor {
    background: transparent linear-gradient(270deg, #0099ff 0%, #cc0001 100%) 0% 0% no-repeat padding-box;
    height: 10px;
}

.intro-value__main__detail>p {
    margin: 10px 0 25px;
    font-size: 18px;
}

.intro-value__main__detail__title {
    border-bottom: 1px solid #cc0001;
    padding-bottom: 10px;
}

.intro-value__main__detail__title>h3 {
    font: normal normal bold 24px/30px Open Sans;
    color: #cc0001;
    text-transform: uppercase;
}

.intro-value__main__detail__title>h4 {
    font: normal normal bold 20px/30px Open Sans;
    color: #cc0001;
    text-transform: uppercase;
    margin: 0;
}

.intro-value__main__detail__diagram {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.intro-value__main__detail__diagram__item {
    position: relative;
}

.intro-value__main__detail__diagram__item--line {
    /* position: absolute;
    right:0;
    top:50%;
    transform: translateY(-50%);
    height: 70%;
    width: 1px;
    background-color: #cc0001; */
}

.intro-value__main__detail__diagram__item__nature {
    font: normal normal bold 20px/44px Open Sans;
    color: #ffffff;
    text-transform: uppercase;
    background: #cc0001 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 24px;
    width: 240px;
    height: 48px;
    text-align: center;
    margin: 50px 0;
    position: relative;
    z-index: 11;
}

.intro-value__main__detail__diagram__item__nature--line {
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    width: 26px;
    height: 1px;
    background-color: #cc0001;
}

.intro-value__main__detail__diagram__item__nature--line::before {
    content: "";
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100%;
    height: 20px;
    width: 20px;
    background-color: #cc0001;
}

.intro-value__main__detail__diagram__item__nature--line::after {
    content: "";
    position: absolute;
    top: 0;
    left: calc(100% + 10px);
    width: 1px;
    height: 100px;
    background-color: #cc0001;
}

.intro-value__main__detail__diagram__item__nature:last-child>.intro-value__main__detail__diagram__item__nature--line:after {
    position: unset;
}

.intro-value__main__detail__diagram__item:last-child {
    width: 350px;
    height: 350px;
    position: relative;
    margin-left: auto;
}

.intro-value__main__detail__diagram__item__circle-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent linear-gradient(180deg, #0099ff 0%, #cc0001 100%) 0% 0% no-repeat padding-box;
    opacity: 0.6;
    border-radius: 100%;
}

.intro-value__main__detail__diagram__item:last-child::after {
    content: "";
    position: absolute;
    top: 50%;
    left: -10px;
    transform: translateY(-50%);
    border-width: 40px 40px;
    border-style: solid;
    border-color: transparent transparent transparent #cc0001;
}

.intro-value__main__detail__diagram__item__circle-main {
    width: 255px;
    height: 255px;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
}

.intro-value__main__detail__diagram__item__circle-main>p {
    width: 70%;
    text-align: center;
    font: normal normal normal 24px/44px Open Sans;
    color: #00558d;
}

.intro-value__main__detail__diagram__item:last-child::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    width: 350px;
    height: 1px;
    background-color: #cc0001;
}

.intro-value__main__detail__diagram__item__circle-main__title {
    position: absolute;
    bottom: 50%;
    right: calc(100% + 42px);
    width: 350px;
    font: italic normal bold 26px/44px Open Sans;
    color: #1b1b1b;
    text-align: center;
}

.intro-value__main__detail__diagram__item:last-child {
    background-color: white;
}

.intro-value__main__ending__item {
    background-color: white;
}

.intro-value__main__ending__item:first-child {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 595px;
}

.intro-value__main__ending__item:last-child {
    padding: 30px 70px;
}

.intro-value__main__ending__item>p {
    font: normal normal normal 18px/30px Open Sans;
    margin-bottom: 20px;
}

.intro-detail__main__image {
    width: 100% !important;
    margin: 0 auto !important;
    height: auto !important;
}

.intro-detail__main__image>img {
    width: 100% !important;
    height: auto !important;
}

@media only screen and (max-width: 1320px) {
    .intro-value__main__detail__diagram {
        display: block;
    }

    .intro-modal__main__content div img {
        width: 90% !important;
        height: auto !important;
    }

    .intro-value__main__detail__diagram__item__nature--line::after,
    .intro-value__main__detail__diagram__item__nature--line::before {
        display: none;
    }

    .intro-value__main__detail__diagram__item__nature {
        margin: 50px auto;
    }

    .intro-value__main__detail__diagram__item__nature--line {
        position: absolute;
        left: 50%;
        top: 100%;
        transform: translateX(-50%);
        width: 1px;
        height: 100px;
        background-color: #cc0001;
    }

    .intro-value__main__detail__diagram__item__circle-main__title {
        bottom: calc(100% - 15px);
        left: calc(50% + 20px);
        height: 300px;
        width: 40px;
    }

    .intro-value__main__detail__diagram__item:last-child {
        margin: 300px auto 0;
    }

    .intro-value__main__detail__diagram__item:last-child::before {
        bottom: 100%;
        left: 50%;
        transform: translate(-50%, 0);
        width: 1px;
        height: 350px;
        right: unset;
        top: unset;
    }

    .intro-value__main__detail__diagram__item:last-child::after {
        top: unset;
        left: 50%;
        top: -25px;
        transform: translate(-50%, 0);
        border-width: 40px 40px;
        border-style: solid;
        border-color: #cc0001 transparent transparent transparent;
    }

    .intro-value__main__detail__diagram__item__nature:last-child .intro-value__main__detail__diagram__item__nature--line {
        display: none;
    }

    /* .intro-modal {
        width: 90%;
    } */
}

/* Customize introarchive */
@media only screen and (max-width: 1050px) {
    .intro-achieve__title {
        padding: 20px 60px;
    }
}

/* Main responsive */
@media only screen and (max-width: 1024px) {
    /* .intro-achieve {
        margin-top: 100px;
        padding-right: 40px;
        padding-left: 40px;
    } */

    .intro__overlay {
        height: 90%;
    }

    .intro-value__main__ending__item:last-child {
        padding: 20px 40px;
    }
}

@media only screen and (max-width: 720px) {

    /* Intro about */
    /* .intro-about__content__img {
        width: 80%;
        height: 450px;
        right: 45px;
    }
    .intro-about__heading div:last-child {
        height: 400px;
    } */
    .intro-achieve__title {
        padding: 20px 20px;
        width: calc(100% - 20px);
    }

    .intro-detail__main {
        padding: 10px;
    }

    .intro-image__container table img {
        width: 100% !important;
    }


    .intro-achieve__title p:last-child {
        font-size: 20px;
    }

    .intro-achieve__title p:first-child {
        font-size: 16px;
    }

    .intro-achieve__main__detail {
        justify-content: center;
    }

    .intro-achieve__main__detail--sub {
        padding: 20px;
    }

    .intro-value__main__detail {
        padding: 30px;
    }

    .intro-value__main__ending__item:last-child {
        padding: 30px;
    }
}

@media only screen and (max-width: 550px) {
    .intro-about__heading__item {
        width: 100%;
        min-height: 0px !important;
    }
    .intro-about__content__img {
        height: 430px;
        width: 90%;
        position: relative;
    }

    .intro-about__heading div:last-child {
        height: fit-content;
    }

    .intro-about__content {
        padding-left: 15px;
        padding-right: 15px;
    }

    .intro-generation {
        padding: 20px 0;
    }

    .intro-modal {
        width: 100%;
    }

    .intro-modal__main__content div img {
        width: 90% !important;
        height: auto !important;
    }

    .intro-modal__main__img {
        width: 100%;
        padding: 3px;
        margin: 10px;
    }

    .intro-image__container img {
        /* width: 90% !important;
        height: auto !important; */
    }

    /* .intro-about__content::after {
        border-width: 36px 38px;
    }
    .intro-about__content::after {
        border-width: 36px 38px;
    } */
    .intro-achieve__item>p {
        width: 100%;
    }

    .intro-value__main__detail__diagram__item:last-child {
        width: 280px;
        height: 280px;
    }

    .intro-value__main__detail__diagram__item__circle-main {
        width: 220px;
        height: 220px;
    }

    .intro-generation__title {
        padding-bottom: 20px;
        font-size: 20px;
    }

    .breadcrum__main__item span:first-child {
        margin: 0 3px;
    }

    .intro-about__heading__title {
        font-size: 20px;
    }

    .intro-achieve__main__title,
    .intro-value__main__title,
    .intro-organize__main__title {
        width: unset;
    }

    .intro-modal__item h2 {
        font-size: 16px;
    }

    .intro-modal__heading,
    .intro-modal__main {
        padding: 10px 20px;
    }

    .intro-modal__item>span {
        font-size: 16px;
    }

    .intro-about__heading__main td {
        width: unset;
    }

    .intro-about__heading__main th,
    .intro-about__heading__main td {
        display: block;
    }

    .intro-agency__map__area__main,
    .intro-agency__map__area__main__all {
        font-size: 14px;
    }

    .intro-agency__map__image__dots__line-right,
    .intro-agency__map__image__dots__line-left {
        height: 60px;
    }
}

@media only screen and (max-width: 370px) {
    .intro-about__content__img {
        height: 350px;
        width: 90%;
    }

    .intro-about__heading {
        height: fit-content;

    }

    .intro-about__heading div:last-child {
        height: fit-content;
    }

    .intro-value__main__detail__diagram__item:last-child {
        width: 220px;
        height: 220px;
    }

    .intro-value__main__detail__diagram__item__circle-main {
        width: 180px;
        height: 180px;
    }

    .intro-value__main__detail__diagram__item__circle-main>p {
        font-size: 18px;
    }

    .intro-value__main__detail__diagram__item__nature {
        width: 220px;
    }
}