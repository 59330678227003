.relation-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000004d;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  margin-top: 20px;
}

.relation-generation {
  position: relative;
  padding: 60px 0;
  color: #ffffff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.relation-generation__overlay {
  background: transparent linear-gradient(180deg, #00558d 0%, #82cdff 100%) 0% 0% no-repeat padding-box;
  opacity: 0.9;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Relation Page */
.relation__main {
  padding: 50px 100px;
}

.relation__main__item__empty {
  color: #1b1b1b;
  text-align: center;
  font-style: italic;
}

.relation__main-detail {
  padding: 40px;
}

.relation__main--list-item {
  margin-bottom: 60px;
}

.relation__main--list-item:last-child {
  margin: 0;
}

.relation__main__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.relation__main__title__main {
  display: flex;
  align-items: center;
}

.relation__main__title__main>h2 {
  font: normal normal bold 20px/20px Open Sans;
  text-transform: uppercase;
}

.relation__main__title__main__circle {
  width: 25px;
  height: 25px;
  border: 8px solid #cc0001;
  border-radius: 100%;
  margin-right: 10px;
}

.relation__main__title__sub {
  position: relative;
  display: flex;
  /* align-items: center; */
  overflow: hidden;
  cursor: pointer;
  min-width: max-content;
  font: italic normal normal 16px/22px Open Sans;
}

.relation__main__title__sub__line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #cc0001;
  transform: translateX(-101%);
  transition: all 0.3s;
}

.relation__main__title__sub:hover .relation__main__title__sub__line {
  transform: translateX(0);
}

.relation__main__title__sub>span {
  color: #cc0001;
}

.relation__main__title__sub__icon {
  font-size: 25px;
}

.relation__main__line-background {
  background: transparent linear-gradient(270deg, #0099ff 0%, #cc0001 100%) 0% 0% no-repeat padding-box;
  height: 5px;
  width: 100%;
  margin: 10px 0;
}

.relation__help {
  position: relative;
  background-color: #ffffff;
  border-radius: 5px;
  margin: 50px 100px 0;
  color: #0099ff;
  font-size: 26px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  box-shadow: 0px 3px 6px #0000004d;
  background-size: cover;
  background-position: right;
}



.relation__help__button__icon>span {
  font-size: 45px;
}

.relation__help__content {
  display: flex;
  text-transform: uppercase;
  color: #0099ff;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
}

/* Relation Select */
.generation-sort__heading__item .ant-select-single.ant-select-open .ant-select-selection-item {
  color: unset;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background: #d6efff 0% 0% no-repeat padding-box;
  border-radius: 5px;
}

/* .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  display: none;
} */
.ant-select-item {
  padding: 10px 12px;
  margin: 2px;
  font-size: 16px;
}

/* Relation Item */
.relation-item {
  padding: 20px 0;
  border-bottom: 1px solid #dfdfdf;
}

.relation-item:first-child {
  padding: 0 0 20px;
}

.relation-item__time {
  text-align: left;
  font: italic normal normal 16px/22px Open Sans;
  color: #707070;
}

.relation-item__main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.relation-item__main__title {
  font: normal normal 600 18px/28px Open Sans;
  color: #cc0001;
  margin-right: 20px;
  width: 80%;
  word-wrap: break-word;
}

.relation-item__main__button {
  display: flex;
  align-items: center;
  min-width: max-content;
  background: #0099ff 0% 0% no-repeat padding-box;
  border-radius: 18px;
  color: #ffffff;
  padding: 5px 15px;
  font: italic normal normal 14px/20px Open Sans;
  border: 1px solid #0099ff;
  transition: all 0.3s;
  cursor: pointer;
}

.relation-item__main__button--view {
  display: flex;
  align-items: center;
  min-width: max-content;
  background: #0099ff 0% 0% no-repeat padding-box;
  border-radius: 18px;
  color: #ffffff;
  padding: 5px 15px;
  font: italic normal normal 14px/20px Open Sans;
  border: 1px solid #0099ff;
  transition: all 0.3s;
  cursor: pointer;
  margin-right: -20px;
}

.relation-item__main__button:hover {
  background-color: #ffffff;
  color: #0099ff;
}

.relation-item__main__button__icon {
  margin-left: 10px;
}

/* Relation Detail */
.relation__main__pagination {
  text-align: end;
  margin: 20px 0 0;
}

.relation__detail__line-background {
  height: 8px;
  position: relative;
  margin: 0;
}

.relation-category-container {
  background-color: #ffffff;
  position: relative;
  color: #1b1b1b;
  margin-bottom: 60px;
  position: relative;
}

.relation-category-container::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translateY(-50%);
  width: 100%;
  height: 1px;
  background-color: #dfdfdf;
}

.relation-generation__heading {
  position: relative;
  display: flex;
}

.relation-generation__heading1 {
  position: relative;
  display: flex;
}

.relation-generation__heading1 h2 {
  margin-bottom: 20px
}

.generation-rangedate__heading__item {
  display: flex;
  width: 290px;
  height: 36px;
}

.generation-rangedate__heading__item label {
  line-height: 36px;
  margin-left: 10px;

}

.generation-rangedate__heading__item__input {
  height: 36px;
  line-height: 36px;
  margin-top: 30px;
}

/* .generation-rangedate__heading__item__input .ant-picker-range-separator {
  position: absolute;
  top: 25%;
  right: 56%;
} */

.generation-rangedate__heading__item .ant-picker.ant-picker-range {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  height: 36px;
  margin-left: 10px
}

.generation-search__heading__item {
  right: 0px;
  background-color: rgb(255, 255, 255);
  background: '#FFFFFF 0% 0% no-repeat padding-box';
  border-radius: 5px;
  opacity: 1;
  position: absolute;
  width: 300px;
  margin-top: 28px;
}

.generation-search__heading__item input {
  border: none;
  border-radius: 5px;
  font-size: 14px;
  padding-left: 10px;
  line-height: 36px;
  text-align: left;
  font: italic normal normal 14px/36px Open Sans;
  letter-spacing: 0px;
  color: rgba(0, 0, 0, 0.85);
  opacity: 1;
  width: 90%
}

.generation-search__heading__item button {
  border: none;
  background-color: #fff;
  color: #0099FF;
  border-radius: 5px;
  font-size: 15px;
  line-height: 36px;
  float: right
}

.generation-rangedate__heading__item {
  width: 60%
}

.container-title {
  font-size: 30px;
  text-align: left;
  font: normal normal bold 22px/38px Open Sans;
  letter-spacing: 0.44px;
  color: #fff;
  text-transform: uppercase;
}





@media (max-width: 1334px) {
  .relation-category-container::after {
      content: unset;
  }
}



.relation-detail-generation {
  padding-top: 0;
}

.relation-category {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.relation-category--mobile {
  display: none;
}

.relation-category--mobile__main {
  margin: 20px 0;
  font-size: 18px;
}

.relation-category--mobile__main__item {
  font-size: 18px;
}

.relation-category--mobile__main__item a {
  display: block;
  width: 100%;
}

.relation-category__item {
  width: 235px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal normal 16px/22px Open Sans;
  color: #1b1b1b;
  position: relative;
  text-align: center;
}

.relation-category__item ::after {
  content: "";
  width: 200px;
  height: 1px;
  background-color: #dfdfdf;
  position: absolute;
  bottom: 100%;
  left: -100%;
}

.relation-category__item.active {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  color: #0099ff;
  font-weight: bold;
}

.box {
  width: 100% !important;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background-color: #0099ff6a;
  max-width: initial;
  overflow: hidden !important;
}

.box .ant-modal-body {
  min-height: calc(100vh - 110px) !important;
  padding: 0 !important;
  overflow: hidden !important;
}

.box>.ant-modal,
.box>.ant-modal-content {
  min-height: 100vh !important;
  width: 100vw !important;
  margin: 0 !important;
  top: 0 !important;
  background-color: transparent !important;
  overflow: hidden !important;
}

#loading-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

#loading-text {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #FFF;
  width: 100px;
  height: 30px;
  margin: -7px 0 0 -45px;
  text-align: center;
  font-family: 'PT Sans Narrow', sans-serif;
  font-size: 20px;
}

#loading-content {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 170px;
  height: 170px;
  margin: -85px 0 0 -85px;
  border: 3px solid #F00;
}

#loading-content:after {
  content: "";
  position: absolute;
  border: 3px solid #0F0;
  left: 15px;
  right: 15px;
  top: 15px;
  bottom: 15px;
}

#loading-content:before {
  content: "";
  position: absolute;
  border: 3px solid #00F;
  left: 5px;
  right: 5px;
  top: 5px;
  bottom: 5px;
}

#loading-content {
  border: 3px solid transparent;
  border-top-color: #4D658D;
  border-bottom-color: #4D658D;
  border-radius: 50%;
  -webkit-animation: loader 2s linear infinite;
  -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
  animation: loader 2s linear infinite;
}

#loading-content:before {
  border: 3px solid transparent;
  border-top-color: #D4CC6A;
  border-bottom-color: #D4CC6A;
  border-radius: 50%;
  -webkit-animation: loader 3s linear infinite;
  -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
  animation: loader 3s linear infinite;
}

#loading-content:after {
  border: 3px solid transparent;
  border-top-color: #84417C;
  border-bottom-color: #84417C;
  border-radius: 50%;
  -webkit-animation: loader 1.5s linear infinite;
  animation: loader 1.5s linear infinite;
  -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
}

@-webkit-keyframes loaders {
  0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

@keyframes loader {
  0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

#content-wrapper {
  color: #FFF;
  position: fixed;
  left: 0;
  top: 20px;
  width: 100%;
  height: 100%;
}

#header {
  width: 800px;
  margin: 0 auto;
  text-align: center;
  height: 100px;
  background-color: #666;
}

#content {
  width: 800px;
  height: 1000px;
  margin: 0 auto;
  text-align: center;
  background-color: #888;
}

.relation-item__main--flex {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */

}

.relation-item__main__button {
  margin-left: 30px;
}

.main__page--container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  position: relative;
  margin: 0 auto;
  padding: 10px;
  overflow: hidden;
  box-sizing: border-box;
}

.main__page--mode {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 10px;
  z-index: 100000;
  width: 100%;
}

.main__page--flip {
  flex: 1;
  transition: 0.5s;
}

.main__page--mode-control {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  height: 50px;
  background: rgb(55 54 54 / 59%);
  border-radius: 30px;
    padding: 10px 20px;

}

.main__page--mode-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.main__page--input {
  width: 50px;
  margin-left: 10px;
}

.main__page--input>input {
  padding: 0;
  text-align: center;
}

.main__page--input input[type=number]::-webkit-inner-spin-button,
.main__page--input input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.main__page--zoom {
  transform: scale(1.2);
}

.main__page--zoomx2 {
  transform: scale(1.2);
}

.main__page--zoomx3 {
  transform: scale(1.5);
}

@media only screen and (max-width: 1024px) {
  .relation__main {
      padding: 50px;
  }
}

@media only screen and (max-width: 880px) {

  .relation__main,
  .relation__main {
      padding: 20px 18px;
  }

  .relation-item__main {
      flex-direction: column;
      align-items: flex-start;
  }

  .relation-item__main__title {
      margin: 10px 0;
      width: 100%;
  }

  .relation__help {
      margin-right: 20px;
      margin-left: 20px;
      padding: 0 20px;
  }

  .relation__main__title {
      flex-direction: column;
      align-items: flex-start;
  }

  .relation__main__title__main {
      margin-bottom: 10px;
  }

  .relation-generation:not(.relation-detail-generation) {
      padding: 40px 0;
  }

  .relation-category {
      display: none;
  }

  .relation-category--mobile {
      display: block;
  }

  .generation-rangedate__heading__item {
      display: block;
  }

  .relation-generation__heading {
      display: block;
      height: 106px
  }

  .relation-generation__heading1 {
      display: block;
      height: 40px
  }

  .generation-rangedate__heading__item {
      display: block;
      height: 50px;
      width: 100%;
  }

  .generation-search__heading__item {
      width: 100%;
      display: block;
      top: 80px
  }

  .relation-item__main__button--view {
      /* margin-left: 30px; */
  }

  .generation-rangedate__heading__item__input {
      margin-top: 0px;
  }

  .generation-search__heading__item {
      margin-top: 0px;
  }

  .relation-item__main--flex {
      width: 27%;
  }
}

.main__page--overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 11111;
}



@media only screen and (max-width: 600px) {
  .relation-item__main__button--view {
      /* margin-bottom: 10px; */
  }

  .relation-item__main--flex {
      width: 60%;
  }
  .main__page--mode-control{
    flex-wrap: wrap;
    height: 80px;
    bottom: 70px;
  }
}

@media only screen and (min-width: 900px) {
  .relation-item__main__button--view {
      transform: translateX(-3px);
  }


}


