.manage-recruitment-modal .ant-modal-body {
  padding: 12px 59.25px
}

.manage-recruitment-modal .ant-modal-header {
  padding: 16px 59.25px;
}

.manage-recruitment-tabs .ant-tabs-tab {
  padding: 12px 24px;
}

.manage-recruitment-tabs .ant-tabs-tab.ant-tabs-tab-active {
  font-weight: bold;
}

.manage-recruitment-tabs .ant-tabs-content-holder {
  padding: 24px 0;
}

.manage-recruitment-form .ant-form-item-label {
  white-space: unset;
}