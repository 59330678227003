.recruit-container {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000004d;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    margin-top: 20px;
}

.recruit-generation {
    position: relative;
    color: #ffffff;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

.recruit-generation__overlay {
    background: transparent linear-gradient(180deg, #00558d 0%, #82cdff 100%) 0% 0% no-repeat padding-box;
    opacity: 0.9;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* recruit Page */
.recruit__main {
    padding: 50px 100px;
}

.recruit-heading {
    position: relative;
    width: 100%;
}

.recruit-heading-item {
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    padding: 50px 30px;
}

.recruit-heading-item>h2 {
    font: normal normal normal 30px/45px Open Sans;
    color: #ffffff;
}

.recruit-heading-item>p {
    margin: 20px 0;
}

.recruit-main {
    position: relative;
    padding: 0 0 60px;
}

.recruit-main__title {
    width: 400px;
    background: transparent linear-gradient(278deg, #cc0001 0%, #00558d 100%) 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 15px;
    font-size: 20px;
    display: flex;
    align-items: center;
    margin-top: 60px;
}

.recruit-main__title__circle {
    width: 25px;
    height: 25px;
    border: 8px solid #ffffff;
    margin-right: 10px;
    border-radius: 100%;
}

.recruit-main__title>h3 {
    color: #ffffff;
    font: normal normal normal 20px/20px Open Sans;
    text-transform: uppercase;
}

.recruit-main__item-container {
    margin: 20px 0;
}

.recruit-main__item {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    color: #1b1b1b;
    padding: 20px 80px;
    position: relative;
}

.recruit-main__item__title {
    position: relative;
    font: normal normal bold 18px/32px Open Sans;
    padding-bottom: 15px;
}

.recruit-main__item__title-EMPTY {
    font: normal normal bold 18px/32px Open Sans;
    color: #acacac;
}

.recruit-main__item__content-EMPTY {
    font: normal normal normal 50px/32px Open Sans;
    color: #acacac;
    text-transform: uppercase;
    padding: 20px 0 15px;
}

.recruit-main__item__title::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -20px;
    width: calc(100% + 40px);
    height: 1px;
    background-color: #acacac;
}

.recruit-main__item__detail {
    padding-top: 15px;
}

.recruit-main__item__detail i {
    margin-right: 10px;
}

.recruit-main__item--background {
    height: 10px;
    background: transparent linear-gradient(270deg, #0099ff 0%, #cc0001 100%) 0% 0% no-repeat padding-box;
}

.recruit-main__item__button {
    position: absolute;
    bottom: 20px;
    right: 80px;
    display: flex;
    /* align-items: center; */
    overflow: hidden;
    cursor: pointer;
    min-width: max-content;
    font: italic normal normal 16px/22px Open Sans;
}

.recruit-main__item__button>span {
    font-size: 25px;
}

.recruit-main__item__button__line {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #0099ff;
    transform: translateX(-101%);
    transition: all 0.3s;
}

.recruit-main__item__button:hover .recruit-main__item__button__line {
    transform: translateX(0);
}

.recruit-button-item-main {
    position: relative;
    margin-left: auto;
}

.recruit-cultural {
    background-color: #ffffff;
    padding: 30px;
    color: #1b1b1b;
    margin-top: 20px;
}

.recruit-cultural__background {
    width: 100%;
    min-height: 250px;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.recruit-cultural__item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.recruit-cultural__item__main>h4 {
    font: normal normal bold 18px/24px Open Sans;
    color: #cc0001;
    text-transform: uppercase;
    padding: 10px 0;
    border-bottom: 1px solid #dfdfdf;
}

.recruit-cultural__item__main>p {
    font: normal normal normal 16px/30px Open Sans;
}

.recruit-main__item__main {
    padding-bottom: 20px;
}

.recruit-main__item__main>h4 {
    font: normal normal normal 18px/24px Open Sans;
    color: #cc0001;
    text-transform: uppercase;
    margin: 10px 0;
}

.recruit-main__item__main>p {
    font: normal normal normal 16px/30px Open Sans;
    color: #1b1b1b;
    margin-top: 30px;
    margin-bottom: 30px;
}

.recruit-main__item__title__detail {
    font-size: 20px;
    padding-top: 10px;
}

/* recruit Select */
.generation-sort__heading__item .ant-select-single.ant-select-open .ant-select-selection-item {
    color: unset;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background: #d6efff 0% 0% no-repeat padding-box;
    border-radius: 5px;
}

/* .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    display: none;
} */
.ant-select-item {
    padding: 10px 12px;
    margin: 2px;
    font-size: 16px;
}

/* recruit Item */
.recruit-item {
    padding: 20px 0;
    border-bottom: 1px solid #dfdfdf;
}

.recruit-item:first-child {
    padding: 0 0 20px;
}

.recruit-item__time {
    text-align: left;
    font: italic normal normal 16px/22px Open Sans;
    color: #707070;
}

.recruit-item__main {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.recruit-item__main__title {
    font: normal normal 600 18px/28px Open Sans;
    color: #cc0001;
    margin-right: 20px;
}

.recruit-item__main__button {
    display: flex;
    align-items: center;
    min-width: max-content;
    background: #0099ff 0% 0% no-repeat padding-box;
    border-radius: 18px;
    color: #ffffff;
    padding: 5px 15px;
    font: italic normal normal 14px/20px Open Sans;
    border: 1px solid #0099ff;
    transition: all 0.3s;
    cursor: pointer;
}

.recruit-item__main__button:hover {
    background-color: #ffffff;
    color: #0099ff;
}

.recruit-item__main__button__icon {
    margin-left: 10px;
}

/* recruit Detail */
.recruit__main__pagination {
    text-align: end;
    margin: 20px 0 0;
}

.recruit__detail__line-background {
    height: 8px;
    position: relative;
    margin: 0;
}

.recruit-category-container {
    background-color: #ffffff;
    position: relative;
    color: #1b1b1b;
    margin-bottom: 60px;
    position: relative;
}

.recruit-category-container::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translateY(-50%);
    width: 100%;
    height: 1px;
    background-color: #dfdfdf;
}

@media only screen and (max-width: 1334px) {
    .recruit-category-container::after {
        content: unset;
    }
}

.recruit-detail-generation {
    padding-top: 0;
}

.recruit-category {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

.recruit-category--mobile {
    display: none;
}

.recruit-category--mobile__main {
    margin: 20px 0;
    font-size: 18px;
}

.recruit-category--mobile__main__item {
    font-size: 18px;
}

.recruit-category__item {
    width: 235px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font: normal normal normal 16px/22px Open Sans;
    color: #1b1b1b;
    position: relative;
}

.recruit-category__item ::after {
    content: "";
    width: 200px;
    height: 1px;
    background-color: #dfdfdf;
    position: absolute;
    bottom: 100%;
    left: -100%;
}

.recruit-category__item.active {
    background: #f5f5f5 0% 0% no-repeat padding-box;
    color: #0099ff;
    font-weight: bold;
}

/* Modal CV */
.modal-cv-overlay {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    min-height: 100vh;
}

.modal-cv {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 575px;
}

.modal-cv-heading {
    padding: 15px 24px;
    border-bottom: 1px solid #dfdfdf;
}

.modal-cv-heading>h4 {
    font: normal normal bold 18px/30px Open Sans;
    letter-spacing: 0px;
    color: #1b1b1b;
    text-transform: uppercase;
    margin: 0;
}

.modal-cv-main {
    padding: 15px 24px;
    max-height: 80vh;
    overflow-y: auto;
}

.modal-cv-main input {
    height: 50px;
}

.modal-cv-main>h4 {
    font: normal normal bold 18px/30px Open Sans;
    color: #1b1b1b;
    text-transform: uppercase;
}

.modal-cv-main>p {
    font: normal normal normal 18px/30px Open Sans;
    color: #1b1b1b;
}

.modal-cv-submit {
    padding: 15px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal-cv-submit--button {
    width: 245px;
    outline: none;
}

.modal-cv-submit--button-main {
    width: 245px;
    border: none;
}

.modal-cv-submit--button~.modal-cv-submit--button {
    margin-left: 10px;
}

p.modal-cv-main-hint {
    font: normal normal normal 14px/30px Open Sans;
    color: #cc0001;
}

p.modal-cv-main-bonus {
    font: italic normal normal 16px/30px Open Sans;
    color: #1b1b1b;
}

.recruit-upfile {
    height: unset !important;
    padding: 14px 10px;
}

/* Send mail success modal */
.mail__result__overlay {
    display: block;
    position: fixed;
    background-color: rgb(27, 27, 27, 0.5);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.modal.mail__result {
    display: block;
    position: fixed;
    top: 50%;
    left: 50%;
    max-width: 1030px;
    width: 100%;
    padding-bottom: 10px;
    transform: translate(-50%, -50%);
    background: transparent linear-gradient(270deg, #0099FF 0%, #CC0001 100%) 0% 0% no-repeat padding-box;
    z-index: 999;
}

.mail__result__content {
    padding: 45px 40px 40px 53px;
    background: #fff;
}

.mail__result__content>.modal__container {
    margin: 0 auto;
}

.mail__result__icon {
    padding-bottom: 40px;
    text-align: center;
}

.mail__result__icon--success {
    color: #0099FF;
    font-weight: bold;
    font-size: 70px;
}

.mail__result__message {
    font: normal normal normal 18px/30px Open Sans;
    text-align: center;
    color: #1B1B1B;
    margin-bottom: 30px;
}

.mail__result__action {
    margin: 0 auto;
    max-width: 250px;
    width: 100%;
    text-align: center;
}

.mail__result__action a:last-of-type {
    display: block;
    margin-top: 20px;
}


@media only screen and (max-width: 767px) {
    .recruit-cultural__col-reverse {
        flex-direction: column-reverse;
    }

    .order__result__message {
        max-height: 150px;
        overflow-y: scroll;
    }
}

@media only screen and (max-width: 575px) {
    .recruit-main__item {
        padding: 10px 20px;
    }

    .recruit-main__title {
        width: unset;
    }

    .recruit-main__item__button {
        position: relative;
        bottom: unset;
        right: unset;
        display: inline-flex;
        margin-left: auto;
    }
}

@media only screen and (max-width: 370px) {
    .recruit-main__item__button {
        position: relative;
    }
}