.contact-generation {
    position: relative;
    color: #ffffff;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

.contact-generation__overlay {
    background: transparent linear-gradient(180deg, #00558d 0%, #82cdff 100%) 0% 0% no-repeat padding-box;
    opacity: 0.9;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.contact-main {
    position: relative;
    padding: 1px 0 60px;
    color: #1b1b1b;
}

.contact-main__title {
    width: 400px;
    background: transparent linear-gradient(278deg, #cc0001 0%, #00558d 100%) 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 15px;
    font-size: 20px;
    display: flex;
    align-items: center;
    margin: 60px 0 20px;
}

.contact-main__title__circle {
    width: 25px;
    height: 25px;
    border: 8px solid #ffffff;
    margin-right: 10px;
    border-radius: 100%;
}

.contact-main__title > h3 {
    color: #ffffff;
    font: normal normal normal 20px/20px Open Sans;
    text-transform: uppercase;
}

.contact-main__form {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    padding: 20px 30px 30px;
}

.contact-main__form-hint {
    font: italic normal normal 16px/30px Open Sans;
    color: #1b1b1b;
    margin-bottom: 14px;
}

.contact-main__form-hint > a {
    font-weight: bold;
}

.contact-main__form-require {
    font: normal normal normal 14px/30px Open Sans;
    color: #cc0001;
}

.contact-main__form--checkcode {
    width: 200px;
}

.contact-main__form > h4 {
    font: normal normal normal 26px/30px Open Sans;
    color: #cc0001;
    text-transform: uppercase;
}

.contact-main__form__checkbox {
    display: flex;
    flex-wrap: wrap;
}

.contact-main__form-title {
    font: normal normal bold 16px/30px Open Sans;
    letter-spacing: 0px;
}

.contact-main__form__main {
}

.contact-main__form__main__field-flex{
    display: flex;
}

.contact-main__form__main__field-flex>.contact-main__form-title{
    margin-right:10px;
}

.contact-main__form__main__field-flex .ant-select-selection-item{
    font-size: 16px;
}

.contact-main__form-title > span {
    font: italic normal normal 16px/30px Open Sans;
    color: #1b1b1b;
}

.contact-button-submit {
    background: #cc0001 0% 0% no-repeat padding-box;
    border-radius: 18px;
    width: 180px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    font: italic normal normal 16px/20px Open Sans;
    color: #ffffff;
    border: 1px solid #cc0001;
    margin-left: auto;
}

.contact-button-submit > i {
    margin-left: 4px;
}

.contact-button-submit:hover,
.contact-button-submit:focus {
    background-color: #ffffff;
    color: #cc0001;
    border: 1px solid #cc0001;
}

.contact-main--background {
    background: transparent linear-gradient(270deg, #0099ff 0%, #cc0001 100%) 0% 0% no-repeat padding-box;
    height: 10px;
}

.contact-main__method {
    background-color: #ffffff;
    padding: 30px;
}

.contact-main__method__table {
    text-align: left;
    width: 100%;
}

.contact-main__method__table th {
    font: normal normal bold 18px/30px Open Sans;
    color: #cc0001;
}

.contact-main__method__table td {
    font: normal normal normal 18px/30px Open Sans;
    color: #1b1b1b;
}

.contact-main__method__table th,
.contact-main__method__table td {
    padding: 10px;
}

.contact-main__method__table th:first-child,
.contact-main__method__table td:first-child {
    padding-left: 0;
}

.contact-main__method__table th:not(.contact-main__method__table th:last-child),
.contact-main__method__table td:not(.contact-main__method__table td:last-child) {
    border-right: 1px solid #00558d;
}

.contact-main__method__table td {
    border-top: 1px solid #00558d;
}

.contact-main__method__background {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    min-height: 284px;
    width: 100%;
    position: relative;
}

.contact-main__method__fade {
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to left, rgba(255 255 255/0), rgba(255 255 255/0.9), rgba(255 255 255/1));
    width: 40%;
    height: 100%;
}

.contact-main__info {
    background-color: #ffffff;
    padding: 30px;
}

.contact-main__info__heading {
    display: flex;
    padding-bottom: 30px;
    border-bottom: 1px solid #dfdfdf;
}

.contact-main__info__heading > img {
    width: 140px;
    height: 140px;
}

.contact-main__info__heading__detail {
    margin-left: 20px;
}

.contact-main__info__heading__detail > h4 {
    font: normal normal bold 22px/32px Open Sans;
    color: #1b1b1b;
}

.contact-main__info__heading__detail i {
    margin-right: 10px;
}

.contact-main__info__phone {
    margin-left: 0;
}

.contact-main__info__ggmap {
    height: 450px;
    margin-top: 30px;
}

.contact-modal-background {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
}

.contact-modal {
    background-color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    text-align: center;
    padding: 30px;
}

.contact-modal > h3 {
    font: normal normal bold 20px/30px Open Sans;
    margin-bottom: 10px;
}

.contact-button-close {
    border-radius: 18px;
    width: 130px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    font: italic normal normal 16px/20px Open Sans;
    color: #cc0001;
    border: 1px solid #cc0001;
    margin: 20px auto 0;
}

.contact-button-close:hover {
    background-color: #cc0001;
    color: #ffffff;
    border: 1px solid #cc0001;
}

.contact-main__form-radio {
    margin: 0px;
}

.contact-main__form-radio__main {
    margin-left: 20px;
    font-size: 16px !important;
}

@media only screen and (max-width: 650px) {
    .contact-main__title {
        width: unset;
    }
    .contact-main__info__heading {
        flex-direction: column;
        align-items: center;
    }
    .contact-main__info__heading__detail {
        text-align: center;
    }
    .contact-main__info__heading > img {
        width: 100px;
        height: 100px;
    }
    .contact-main__method__table th,
    .contact-main__method__table td {
        font-size: 16px;
    }
    .contact-main__method {
        padding: 20px;
    }
}
