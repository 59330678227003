/* Home */

.home__heading__slide .slick-dots {
    bottom: 50px !important;
}

.home__heading__slide ul.slick-dots li {
    width: unset;
    height: unset;
    background-color: #bcbcbc;
    border-radius: 5px;
}

.home__heading__slide .slick-dots li.slick-active {
    background-color: #f5f5f5;
}

.home__heading__slide .slider--dots {
    width: 70px;
    height: 10px;
}

.arrow-slider {
    position: absolute;
    bottom: 30px;
    font-size: 40px;
    color: #ffffff;
    transform: translateX(-50%);
    z-index: 10;
}

.arrow-slider--next {
    left: calc(50% + 200px);
}

.arrow-slider--prev {
    left: calc(50% - 200px);
}

.home__heading {
    position: relative;
}

.home__heading__header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
}

.home__heading__slide {
    text-align: center;
}

.home__heading__slide__item {
    height: 960px;
    position: relative;
}

.home__heading__slide__item__background {
    position: relative;
    width: 70%;
    height: 60%;
    min-height: 380px;
    background: transparent linear-gradient(270deg, rgba(0, 84, 197, 0.4) 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box
}

.home__heading__slide__item__no__background {
    position: absolute;
    top: 197px;
    right: 0;
    width: 50%;
    height: 500px;
}

.home__heading__slide__item-main {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    /* background-size: 100% 100%; */
    background-clip: content-box;
    background-size: cover;
    background-position: top;
    display: flex;
    align-items: center;
    justify-content: end;
}

.home__heading__slide__item__title {
   padding-top: 80px;
    /* padding-right: 80px; */
    text-align: right;
    float: right;
    margin-right: 50px;
    width: 85%;

}


.home__heading__slide__item__title__main>h1 {
    font: normal normal 800 65px/79px Montserrat;
    color: #ffffff;
    text-transform: uppercase;
}

.home__heading__slide__item__title__main>h2 {
    margin-top: 10px;
    font: normal normal normal 45px/55px Montserrat;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: uppercase;
}

/* Main */

.home__main__child {
    /* height: 650px; */
    position: relative;
    padding: 60px 0 40px;
}

.home__main__child--grow-up {
    margin-bottom: 80px;
}

.home__main__child--center {
    position: relative;
    padding-top: 80px;
    overflow: hidden;
}

.home__main__child--intro {
    padding: 72px 0 40px;
    /* height: 560px; */
    margin-bottom: 70px;
}

.home__main__child--center__overlay {
    height: 90%;
    position: absolute;
    background: transparent linear-gradient(310deg, #82cdff 0%, #00558d 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000004d;
    border-radius: 5px 0px 0px 5px;
    right: 0;
    top: 0;
}

.home__main__heading {
    justify-content: space-between;
    align-items: center;
}

.home__main__title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.home__main__title__circle {
    width: 50px;
    height: 50px;
    border: 10px solid #0099ff;
    border-radius: 100%;
}

.home__main__title__circle--red {
    border: 10px solid #cc0001;
}

.home__main__title__circle--blue {
    border: 10px solid #0099ff;
}

.home__main__title__main {
    margin: 0 15px;
    letter-spacing: 0px;
    color: #1b1b1b;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 28px;
}

.home__main__button-container {
    display: flex;
    justify-content: flex-end;
}

.home__main__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 170px;
    height: 40px;
    border-radius: 20px;
    font: italic normal normal 16px/22px Open Sans;
    transition: all 0.3s;
    cursor: pointer;
}

.home__main__buttonDownload {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 270px; */
    height: 40px;
    border-radius: 20px;
    font: italic normal normal 16px/22px Open Sans;
    transition: all 0.3s;
    cursor: pointer;
    margin-top: 60px;
}

.home__main__button-icon>i {
    margin-left: 10px;
}

.home__main__button__icon {
    font-size: 34px;
}

.home__main__button__icon--download {
    font-size: 24px;
    margin-left: 10px;
}

.home__main__button--red {
    color: white;
    background: #cc0001 0% 0% no-repeat padding-box;
}

.home__main__buttonDownload--red {
    color: white;
    background: #cc0001 0% 0% no-repeat padding-box;
    min-width: 140px;
    padding: 10px 20px;
}

.home__main__button--banner {
    position: absolute;
    margin-top: 10px;
    bottom: 50px;
    right: 50px;
    margin-left: auto;
    background-color: transparent;
    border: 1px solid #ffffff;
    color: #ffffff;
    width: 220px;
}

.home__main__button--banner:hover {
    color: #0099ff;
    background-color: white;
}

.home__main__button--red-empty {
    color: #cc0001;
    border: 1px solid #cc0001;
    background: #ffffff 0% 0% no-repeat padding-box;
}

.home__main__button--red:hover {
    color: #cc0001;
    border: 1px solid #cc0001;
    background: #ffffff 0% 0% no-repeat padding-box;
}

.home__main__buttonDownload--red:hover {
    color: #cc0001;
    border: 1px solid #cc0001;
    background: #ffffff 0% 0% no-repeat padding-box;
}

.home__main__button--red-empty:hover {
    color: white;
    background: #cc0001 0% 0% no-repeat padding-box;
}

.home__main__news {
    margin-top: 30px;
}

.home__main__news__overlay {
    /* mix-blend-mode: overlay; */
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
}

.home__main__news-container {
    top: -70px;
    left: -18%;
    width: 118%;
    height: 123%;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 4px 4px 4px rgb(0 0 0 / 15%)
}

.home__main__news__item {
    /* width: 370px; */
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 30px;
    background-color: white;
}

.home__main__news__item__img {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: initial;
}

.home__main__news__item__content {
    padding: 15px 20px;
    position: relative;
    height: 130px;
}

.home__main__news__item__content__detail {
    position: absolute;
    right: 17px;
    bottom: 10px;
}

.home__main__news__item__content__detail__main {
    color: #0099ff;
    font-style: italic;
    position: relative;
    overflow: hidden;
    width: max-content;
    margin-left: auto;
}

.home__main__news__item__content__detail__main__item {
    display: flex;
    align-items: center;
    color: #0099ff;
}

.home__main__news__item__content__detail__main__item .home__main__button__icon {
    font-size: 20px;
}

.home__main__news__item__content__detail--line {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #0099ff;
    transform: translateX(calc(-100% - 1px));
    transition: all 0.3s;
}

.home__main__news__item__content__detail>div:hover .home__main__news__item__content__detail--line {
    transform: translateX(0);
}

.home__main__news__item__content>span {
    font: normal normal normal 14px/19px Open Sans;
    color: #acacac;
}

.home__main__news__item__content p {
    margin-bottom: 16px;
    font: normal normal normal 16px/26px Open Sans;
    letter-spacing: 0px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Grow up */
.home__main__grow-up-container {
    margin-left: auto;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 115px 0;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
}

.home__main__grow-up {
    padding: 100px 0;
    margin-left: auto;
}

.home__main__grow-up__overlay {
    width: 50%;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    background: rgba(255, 255, 255, 0.8) 0% 0% no-repeat padding-box;
    margin-left: auto;
    box-shadow: 0px 3px 6px #00000033;
    height: 400px;
}

.home__main__grow-up__main {
    width: 40%;
    margin-left: auto;
    position: relative;
    z-index: 10;
}

.home__main__grow-up__title {
    padding-bottom: 24px;
    border-bottom: 1px solid #707070;
}

.home__main__grow-up__content {
    padding: 28px 0;
    font-size: 30px;
    color: #cc0001;
    font: normal normal bold 30px/40px Open Sans;
}

.home__main__grow-up__button {
    display: flex;
    justify-content: flex-end;
}

.home__main__intro {
    margin-top: 40px;
}

.home__main__intro__overlay {
    height: 80%;
    background: transparent linear-gradient(285deg, #82cdff 0%, #00558d 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    position: absolute;
    top: 0;
    right: 0;
}

.home__main__intro__list {
    margin-left: -18px;
}

.home__main__intro__list .slick-list {}

.home__main__intro__list .slick-slide {
    margin-right: -1px;
}

/* .home__main__intro__list .slick-active:last-child .home__main__intro__list__item{
    margin-right: 0;
} */

.home__main__intro__list__item {
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    text-align: right;
    font: normal normal normal 18px/20px Open Sans;
    letter-spacing: 0.36px;
    color: #00558d;
    text-transform: uppercase;
    font-size: 18px;
    margin-left: 20px;
}

.home__main__intro__list__item>a {
    font: normal normal bold 18px/20px Open Sans;
    color: #00558d;
    text-transform: uppercase;
    text-align: left;
    display: flex;
    justify-content: flex-end;
}

.home__main__intro__list__item:hover .home__main__intro__list__item-container {
    opacity: 1;
}

.home__main__intro__list__item__img {
    height: 450px;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 5px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
}

.home__main__intro__list__item__overlay {
    background: transparent linear-gradient(141deg, #cc0001 0%, #0099ff 100%) 0% 0% no-repeat padding-box;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.36px;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 24px;
    text-align: center;
}

.home__main__intro__list__item__main {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    display: flex;
    align-items: center;
}

.home__main__intro__list__item__main__detail {
    width: calc(60%);
    text-align: start;
    color: #ffffff;
}

.home__main__intro__list__item-container {
    opacity: 0;
    transition: all 0.3s;
}

.home__main__intro__list__item__main__detail>p {
    text-transform: uppercase;
    margin-bottom: 10px;
    font-size: 30px;
    line-height: 45px;
}

.home__main__intro__list__item__main__detail__child {
    font-size: 16px;
    display: flex;
    align-items: center;
    font-weight: 400;
    position: relative;
    overflow: hidden;
    width: max-content;
    cursor: pointer;
}

.home__main__intro__list__item__main__detail__child>a {
    display: flex;
    align-items: center;
    color: #ffffff;
    text-transform: none;
}

.home__main__intro__list__item__main__detail__child:hover .home__main__intro__list__item__main__detail__line {
    transform: translateX(0);
}

.home__main__intro__list__item__main__detail__line {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #ffffff;
    transform: translateX(calc(-100% - 1px));
    transition: all 0.3s;
}

.home__main__intro__list__item__main__detail__child>a>span {
    font-size: 40px;
}

/* ArrowSliderIntro */
.arrow-slider-intro {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    position: absolute;
    left: calc(100% + 14px);
    transform: translate(0, -50%);
    font-size: 60px;
    border: 1px solid #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    background-color: white;
    color: #0099ff;
    cursor: pointer;
}

.arrow-slider-intro--next {
    top: calc(50% + 10px);
}

.arrow-slider-intro--prev {
    top: calc(50% + 80px);
    background-color: transparent;
    color: white;
}

.arrow-slider-intro--prev>span {
    margin-top: 5px;
    transform: rotate(-180deg);
}

/* .arrow-slider-intro:hover {
    color: white;
    background-color: transparent;
} */

.arrow-slider-intro--next:hover {
    color: white;
    background-color: transparent;
}

.arrow-slider-intro--prev:hover {
    background-color: white;
    color: #0099ff;
}

/* NEWS-EVENT */
.home__main__news-event--overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: transparent linear-gradient(287deg, #82cdff 0%, #00558d 100%) 0% 0% no-repeat padding-box;
    height: 100%;
    box-shadow: 0px 3px 6px #0000004d;
    border-radius: 5px;
}

.home__main__news-event {
    position: relative;
}

.home__main__news-event__item {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    overflow: hidden;
    border-bottom: 1px solid #ffffff;
    color: #d6efff;
    padding: 0 14px 20px;
}

.home__main__news-event__item~.home__main__news-event__item {
    margin-top: 40px;
}

.home__main__news-event__item__content {
    font-weight: bold;
}

.home__main__news-event__item__content__sub {
    font: normal normal 300 14px/19px Open Sans;
}

.home__main__news-event__item__content__sub>a {
    font: normal normal 300 14px/20px Open Sans;
    color: #d6efff;
}

.home__main__news-event__item__content__main {
    font: normal normal normal 18px/20px Open Sans;
    margin-right: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.home__main__news-event__item__content__detail {
    font: italic normal normal 14px/19px Open Sans;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    min-width: max-content;
    color: #d6efff;
}

.home__main__news-event__item__content__detail--line {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: white;
    transform: translateX(calc(-100% - 1px));
    transition: all 0.3s;
}

.home__main__news-event__item__content__detail:hover .home__main__news-event__item__content__detail--line {
    transform: translateX(0);
}

.home__main__news-event__item__content__detail:hover {
    color: white;
}

.home__main__news-event__item__content__detail p {
    width: max-content;
}

.home__main__child__small-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.home__main__child__small-heading>h3 {
    text-transform: uppercase;
}

.home__main__child__small-heading__detail {
    display: flex;
    justify-content: space-between;
    font: italic normal normal 16px/22px Open Sans;
    color: #0099ff;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    min-width: fit-content;
}

.home__main__child__small-heading__detail__line {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #0099ff;
    width: 100%;
    transform: translateX(calc(-100% - 1px));
    transition: all 0.3s;
    height: 2px;
}

.home__main__child__small-heading__detail:hover .home__main__child__small-heading__detail__line {
    transform: translateX(0);
}

.home__main__child__small-heading__detail span {
    font-size: 26px;
}

.home__main__child__small-background {
    background: transparent linear-gradient(270deg, #0099ff 0%, #cc0001 100%) 0% 0% no-repeat padding-box;
    height: 10px;
}

.home__main__child__small-main-container {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    margin-bottom: 20px;
    height: 250px;
}

.home__main__child__small-main {
    padding: 15px;
}

.home__main__child__small-main table {
    table-layout: fixed;
    width: 100%;
}

.home__main__child__small-main table td,
.home__main__child__small-main table th {
    display: block;
    width: 100%;
}

.home__main__child__small-main table {
    table-layout: fixed;
    width: 100%;
}

.home__main__child__small-main table td img {
    width: 90% !important;
}

.home__main__child__small-main img {
    width: 22% !important;
    height: auto !important;
}

.home__main__child__small-main img {
    margin: 4px;
}

.home__heading__slide__item__background__hidden{
    background: transparent !important;
}

@media only screen and (max-width: 1200px) {
    .home__heading__slide__item__title__main h2 {
        font-size: 20px;
        line-height: 20px;
    }

    .home__heading__slide__item__title__main h1 {
        font-size: 50px;
        line-height: 50px;
    }

    .home__heading__slide__item__title {
        right: 5%;
    }
}

/* Custom Home */
@media only screen and (max-width: 1024px) {
    .arrow-slider-intro--prev {
        top: unset;
        bottom: calc(100% - 25px);
        right: calc(50% + 10px);
        left: unset;
    }

    .home__main__child__small-main-container {
        height: auto;
    }

    .arrow-slider-intro--next {
        top: unset;
        bottom: calc(100% - 25px);
        left: calc(50% + 10px);
        right: unset;
    }

    .arrow-slider-intro:hover {
        background-color: #00558d;
    }

    .home__heading__slide__item__background {
        
        height: 60%;
        min-height: 250px;
        width: 100%;
    }

    .home__heading__slide__item__no__background {
        top: 23%;
        height: 31.25%;
        width: 87%;
    }

    .home__heading__slide__item__title {
        width: 100%;
        top: 40%;
        padding-right: 50px;
    }

    .home__heading__slide__item__title__main h1 {
        font-size: 50px;
        line-height: 61px;
    }

    .home__heading__slide__item__title__main h2 {
        font-size: 35px;
        line-height: 43px;
        font-weight: normal;
    }

    .home__main__button--banner {
        margin-top: 35px;
        margin-left: auto;
        padding: 10px 30px;
        font-size: 18px;
        width: max-content;
        /* height: 100%; */
        right: 20px;
    }

    .home__main__child__small-main img {
        width: 50% !important;
        height: auto !important;
    }
}

/* Custom homeSliderHeading */
@media only screen and (max-width: 790px) {

    .home__main__register {
        justify-content: center;
    }

    .home__main__register>p {
        margin-bottom: 10px;
    }

    .home__main__intro__list__item__img {
        height: 300px;
    }

    .home__main__intro__overlay {
        height: 70%;
    }

    .home__main__child--intro {
        margin-bottom: 30px;
        padding-top: 60px;
    }

    .arrow-slider-intro {
        bottom: calc(100% - 8px);
        width: 40px;
        height: 40px;
        font-size: 40px;
    }

    .home__main__button--banner {
        margin-top: 60px;
        font-size: 11px;
        width: unset;
        height: unset;
        display: inline-flex;
        padding: 5px 20px;
        bottom: 50px !important;
    }

    .home__heading__slide__item__background {
      
        height: 50%;
        width: 100%;
    }

    .home__heading__slide__item__no__background {
        top: 23%;
        height: 30%;
        width: 87%;
    }

    .home__heading__slide__item__title {
        top: 40%;
        right: 2%;
        padding-right: 20px;
    }

    .home__heading__slide__item__title__main h1 {
        font-size: 50px;
        line-height: 33px;
        margin-bottom: 20px;
    }

    .home__heading__slide__item__title__main h2 {
        margin-top: 7px;
        font-size: 35px;
        line-height: 23px;
    }

    .home__heading__slide .slick-dots {
        bottom: 33px !important;
    }

    .home__heading__slide .slider--dots {
        width: 45px;
        height: 6px;
    }

    .arrow-slider--next {
        left: calc(50% + 130px);
        font-size: 18px;
    }

    .arrow-slider--prev {
        left: calc(50% - 130px);
        font-size: 18px;
    }

    .home__main__buttonDownload {
        /* display: flex; */
        position: fixed;
        z-index: 100;
        width: 50px;
        height: 50px;
        border-radius: 5px;
        top: 53%;
        transform: translateX(9px);
        border: 1px solid #cc0001;
    }

    .home__main__buttonDownload_1 {
        display: flex;
        align-items: center;
        justify-content: center;
        /* width: 270px; */
        height: 40px;
        border-radius: 20px;
        font: italic normal normal 16px/22px Open Sans;
        transition: all 0.3s;
        cursor: pointer;
        margin-top: 20px;

    }

    .home__main__button__icon--download {
        font-size: 34px;
        margin: 0 auto;
        color: #cc0001;
    }

    .home__main__button__icon--download_1 {
        font-size: 16px;
        margin: 0 auto
    }

    .home__main__buttonDownload--red {
        color: #cc0001;
        background: transparent;
        min-width: 0%;
        padding: 0;
    }

    .home__main__buttonDownload--red_1 {
        color: white;
        background: #cc0001;
        min-width: 32%;
        padding: 10px;
        margin-right: 500px;
    }
}

/* Mobile */
@media only screen and (max-width: 600px) {
    .home__main__child:not(.home__main__child--intro) {
        padding: 30px 0;
    }

    .home__main__child--intro {
        margin-bottom: 30px;
    }

    .home__main__title__main {
        margin-left: 5px;
        font-size: 20px;
    }

    .home__main__grow-up-container {
        width: calc(100% - 50px) !important;
    }

    .home__main__grow-up__content {
        font-size: 24px;
    }

    .home__main__grow-up__overlay {
        width: 70%;
        height: 500px;
    }

    .home__main__grow-up__main {
        width: 60%;
    }

    .home__main__register__input input {
        width: unset;
    }

    .home__main__news-event__item__content__main {
        font-size: 14px;
    }

    .ant-picker-range .ant-picker-clear {
        right: 0px !important;
        top: -12px;
    }

    .home__main__buttonDownload {
        /* display: flex; */
        position: fixed;
        z-index: 100;
        width: 35px;
        height: 35px;
        border-radius: 5px;
        top: 372px;
        transform: translateX(-34px);
        border: 1px solid #cc0001;
    }

    .home__main__buttonDownload_1 {
        display: flex;
        align-items: center;
        justify-content: center;
        /* width: 270px; */
        height: 40px;
        border-radius: 20px;
        font: italic normal normal 16px/22px Open Sans;
        transition: all 0.3s;
        cursor: pointer;
        margin-top: 20px;
        margin-left: 10px;
    }

    .home__main__button__icon--download {
        font-size: 26px;
        margin: 0 auto;
        color: #cc0001;
    }

    .home__main__button__icon--download_1 {
        font-size: 16px;
        margin: 0 auto
    }

    .home__main__buttonDownload--red {
        color: #cc0001;
        background: transparent;
        min-width: 0%;
        padding: 0;
    }

    .home__main__buttonDownload--red_1 {
        color: white;
        background: #cc0001;
        min-width: 32%;
        padding: 10px;
    }

}

@media only screen and (max-width: 575px) {
    .home__main__button-container {
        justify-content: start;
    }

    .home__heading__slide__item__title__main h1 {
        font-size: 27px;
        margin-bottom: 10px;
    }

    .home__heading__slide__item__title__main h2 {
        font-size: 19px;
    }

    .home__main__button home__main__button--banner {
        margin-top: 20px;
    }

}

@media only screen and (max-width: 550px) {
    .home__heading__slide__item {
        height: 350px;
    }

    .home__main__news-event__item {
        padding-right: 5px;
        padding-left: 5px;
    }

}

@media only screen and (max-width: 320px) {
    /* .home__heading__slide__item__title__main h1 {
        font-size: 18px
    }

    .home__heading__slide__item__title__main h2 {
        font-size: 16px;
    } */

}

.ant-picker-clear {
    opacity: 100;
    background-color: unset;
}

.ant-picker-range .ant-picker-clear {
    right: -56px;
}

.custom-clearIcon {
    color: #fff;
}

.custom-clearIcon:hover {
    text-decoration: underline;
}