.ant-input[disabled] {
    color: rgba(0, 0, 0, 0.75);
    background-color: #dfdfdf;
}

.admin-sider {
    height: 100vh !important;
    /* background-color: #1b1b1b; */
    position: sticky !important;
    top: 0;
    left: 0;
}

.admin-sider__logo {
    display: flex;
    height: 60px;
    align-items: center;
    padding: 5px 20px;
    font: normal normal bold 20px/27px Open Sans;
    color: #dfdfdf;
    justify-content: center;
}

/* .admin-sider__menu {
    background-color: #1b1b1b !important;
} */

.admin-sider__menu>.ant-menu-item {
    min-height: 50px;
    font: normal normal normal 16px/22px Open Sans;
}

.admin-sider__logo>img {
    height: 100%;
    margin-right: 5px;
}

/* AdminHeader */
.admin-header {
    background-color: #ffffff;
    height: 60px;
    text-align: end;
}

.admin-header__account {
    position: relative;
    height: 100%;
}

.admin-header__account__user {
    font-size: 16px;
    color: #0099ff;
    display: inline-flex;
    align-items: center;
}

.admin-header__account__user:hover .admin-header__account__user__dropdown {
    display: block;
}

.admin-header__account__user>p {
    margin: 0 5px;
}

.admin-header__account__user__dropdown {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    width: 210px;
    background-color: #ffffff;
}

.admin-header__account__user__dropdown__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
    font-size: 16px;
    height: 50px;
    transition: all 0.3s;
    color: #0099ff;
    cursor: pointer;
}

.admin-header__account__user__dropdown__item:hover {
    color: #ffffff;
    background-color: #0099ff;
}

/* AdminBreadcrum */
.admin-breadcrum-origin {
    display: inline-block;
}

.admin-breadcrum-origin.admin-breadcrum-item {
    color: #82cdff;
}

.admin-breadcrum-origin>i {
    margin-right: 5px;
}

.admin-breadcrum-item {
    font-size: 16px;
    color: #707070;
}

/* AdminContent */
.admin-content {
    padding: 24px;
    margin-bottom: 30px;
    min-height: 360px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000004d;
}

/* AdminMenu */
.admin-menu {
    border: 1px solid #acacac;
    border-radius: 4px;
    overflow: hidden;
}

.admin-menu__heading {
    background-color: #d6efff;
    font-size: 18px;
    font-weight: bold;
    color: #1b1b1b;
    padding: 10px 30px;
}

.admin-menu__main {
    padding: 40px 30px 16px 30px;
}

/* AdminFooter */
.admin-footer {
    background: #ffffff 0% 0% no-repeat padding-box;
    font-size: 13px;
    color: #707070;
}

/* AdminButton */
.admin-button {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 15px 0;
}

.admin-button-search {
    justify-content: flex-end;
}

.admin-button__save {
    background-color: #0099ff;
    color: #ffffff;
    margin-left: 10px;
}

.admin-button__main {
    width: 175px;
    height: 40px;
    border-radius: 4px;
}

.admin-button__main--red {
    background: #cc0001 0% 0% no-repeat padding-box;
    color: #ffffff;
}

.admin-button__delete {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #0099ff;
    color: #0099ff;
}

.admin-button__download-pdf {
    background: #293948 0% 0% no-repeat padding-box;
    color: #ffffff;
    width: 365px;
}

.admin-button__download-pdf>i {
    margin-right: 10px;
}

/* AdminField */

.admin-filed__list__item {
    font-size: 16px;
}

.admin-filed__list__item__required {
    display: none;
    color: #cc0001;
}

.admin-filed__list__item__required.active {
    display: inline;
}

.admin-filed__list__item .ant-picker-range {
    width: 100%;
}

/* AdminTable */
.admin-table table {
    border: 1px solid #acacac;
}

.admin-table .ant-table-cell::before {
    display: none;
}

.admin-table .ant-table-tbody>tr>td {
    border-bottom: 1px solid #acacac;
    max-width: 250px;
}

.admin-table .ant-table-thead>tr>th {
    border-bottom: 1px solid #acacac;
    background-color: #d6efff;
    font-weight: bold;
}

.admin-button-detail {
    color: #0099ff;
    cursor: pointer;
}

.admin-button-detail:hover {
    text-decoration: underline;
}

/* AdminModal */
.admin-modal .ant-modal-content {
    border-radius: 5px;
    overflow: hidden;
}

.admin-modal .ant-modal-header {
    border-bottom: 1px solid #acacac;
}

.admin-modal .ant-modal-title {
    font-weight: bold;
}

.admin-modal .ant-modal-footer {
    border-top: 1px solid #acacac;
}

/* AdminContent */
.admin-content-container {
    padding: 0 50px;
    color: #DFDFDF;
}

.admin-content {
    padding: 24px;
    min-height: 360px;
}

/* AdminInput */
.admin-input {
    border-radius: 4px;
    overflow: hidden
}

.admin-input .ant-select-selector {
    border-radius: 4px !important;
}

.admin-input-upload-file {
    display: flex;
    align-items: center;
    justify-content: center;
}

.admin-input-upload-view {
    flex-wrap: wrap;
    margin-left: -20%;
}

.admin-input-upload-file #upload-file {
    display: none;
}

.admin-input-upload-file__browser {
    width: 150px;
    height: 30px;
    margin-left: 10px;
    background: #f8f8f8 0% 0% no-repeat padding-box;
    border: 1px solid #acacac;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0099ff;
    cursor: pointer;
}

.admin-input-view-file-button {
    display: flex;
}

.admin-input-upload-file-view {
    width: 220px;
    border: 1px solid #acacac;
}

.admin-input-upload-file-button {
    display: flex;
    margin-top: 10px;
    margin-left: 34%;
}

.admin-input-upload-file-view__button {
    width: 70px;
    height: 30px;
}

.admin-input-view-file__button {
    width: 70px;
    height: 30px;
}

.admin-input-view-file-flip__button {
    width: 150px;
    height: 30px;
}

.admin-icon-input {
    font-size: 15px;
    color: #82CDFF;
}

.react-pdf__Page {
    padding: 10px 0px 10px 10px;
}

/* Responsive */
@media only screen and (max-width: 1600px) {
    .admin-sider__logo>p {
        display: none;
    }

    .admin-sider__logo>img {
        margin: 0;
    }

    .admin-header,
    .admin-content-container {
        padding: 0 10px;
    }

    .admin-content {
        padding: 12px;
    }
    
}

.react-pdf__Page__annotations.annotationLayer {
    display: none;
}

@media only screen and (max-width: 800px) {}

@media only screen and (max-width: 500px) {
    .admin-button-search {
        justify-content: center;
    }

    .admin-button__save {
        margin: 10px 0 0;
    }

    .admin-button__main--red {
        margin: 10px 0;
    }
}